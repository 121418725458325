import { Avatar, Box, Button, Chip, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
interface ChatMessageProps {
  isUserMesage?: boolean;
  message?: string;
  avatar?: string;
  files?: string[];
  date?: string;
  isSystem?: boolean;
  dateFormat?: (date: string) => string;
  [key: string]: any;
}

//TODO FILE
export const ChatMessage: FC<ChatMessageProps> = ({
  isUserMesage = false,
  isSystem = false,
  message,
  avatar,
  files,
  date,
  dateFormat = (date: string) =>
    dayjs(date).format(t("dates.abbreviation_month_date_hour")),
  ...props
}) => {
  const { t } = useTranslation("common");
  const [modalOpened, setModalOpened] = useState(-1);

  if (isSystem) {
    return (
      <Box className="rounded-full text-[#5c6066]">
        {date && (
          <Typography
            className="px-2"
            sx={{
              fontSize: "10px",
            }}
            variant="subtitle2"
            align="center"
          >
            {dateFormat(date)}
          </Typography>
        )}
        <Typography className="break-all" align="center">
          {message}
        </Typography>
        {files &&
          <Box className="flex w-full justify-center">
            {files?.map((file, i) => (
              [
                <Modal
                  key={`modal-img-${i}`}
                  className="overflow-y-auto flex items-center justify-center p-5 md:p-24"
                  open={modalOpened === i}
                  onClose={() => setModalOpened(-1)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  {/* <Box className="flex items-center justify-center block overflow-hidden relative rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-5/6 w-3/4 shadow-md"> */}
                  <img key={`img-${i}`} src={file} />
                  {/* </Box> */}
                </Modal>,
                <Button key={`button-img-${i}`} onClick={() => setModalOpened(i)}><Box className="p-4"><img className="rounded-lg" src={file} /></Box></Button>
              ]
            ))}
          </Box>
        }
      </Box>
    );
  } else {
    return (
      <Box
        className={
          "flex items-end " + (isUserMesage ? "flex-row-reverse" : "flex-row")
        }
      >
        <Avatar src={avatar} />
        <Box className={isUserMesage ? "mr-2" : "ml-2"}>
          {date && (
            <Typography
              className="px-2"
              sx={{
                fontSize: "10px",
              }}
              variant="subtitle2"
              align={isUserMesage ? "right" : "left"}
            >
              {dateFormat(date)}
            </Typography>
          )}
          <Box
            className={
              "rounded-[20px] py-2 px-4 " +
              (isUserMesage ? "bg-fcosecondary" : "bg-fcogrey")
            }
          >
            <Typography className="whitespace-pre-wrap" sx={{ color: isUserMesage ? "white" : "inherit" }}>
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};
