import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { addUpdatingTickets } from "../reducers/Ticket.reducer";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export const generateTicketFileFormat = (ticket: Partial<ITicket>) => {
  const newTicket = { ...ticket };
  if (
    newTicket.ticket_intervention &&
    newTicket.ticket_intervention.length > 0
  ) {
    const steps = newTicket.ticket_intervention[0].intervention_step;
    newTicket.ticket_intervention[0].intervention_step = steps?.map((step) => {
      step.intervention_step_attachment = step.intervention_step_attachment.map(
        (file) => {
          if (!file.intervention_step && !file.linked_file) {
            return { linked_file: file.id };
          }
          return file;
        }
      );
      return step;
    });
  }
  return ticket;
};

export const getTicketsList = createAsyncThunk(
  "ticket/list",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    return FDK.Marina.TicketsModule.getTicketsList(params).then(
      (res) => res.data
    );
  }
);

export const getTicketsClosedList = createAsyncThunk(
  "ticket/listclosed",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    console.log("getTicketsClosedList");

    return FDK.Marina.TicketsModule.getTicketsClosedList(params).then(
      (res) => res.data
    );
  }
);

export const getTicketDetail = createAsyncThunk(
  "ticket/detail",
  async ({ id }: { id: string }, { dispatch, getState }) => {
    // TO CHECK migth be a bit restrictive but fit current use case
    const selectedID = (getState() as RootState).tickets.selectedID;
    if (selectedID !== id) return Promise.reject();
    return FDK.Marina.TicketsModule.getTicketsById(id).then((res) => res.data);
  }
);

export const getTicketsCategories = createAsyncThunk(
  "ticket/categories",
  async (args, { dispatch, getState }) => {
    return FDK.Marina.TicketsModule.getTicketsCategories().then(
      (res) => res.data
    );
  }
);

// export const createTicket = createAsyncThunk("ticket/create",
//   async (ticket: Partial<Ticket>, { dispatch, getState }) => {
//     return Marina.TicketsModule.createTicket(ticket).then(res => res.data)
//   }
// )

export const createTicket = createAsyncThunk(
  "ticket/create",
  async (ticket: Partial<ITicket>, { dispatch }) => {
    return FDK.Marina.TicketsModule.createTicket(
      generateTicketFileFormat(ticket)
    ).then((res) => {
      // const newTicket = res.data;
      // dispatch(addTicket(newTicket));
      return res.data as ITicket;
    });
  }
);

export const updateTicket = createAsyncThunk(
  "ticket/update",
  async (
    { id, ticket }: { id: UUID; ticket: FormData | Partial<ITicket> },
    { dispatch }
  ) => {
    dispatch(addUpdatingTickets(id));
    return FDK.Marina.TicketsModule.updateTicket(id, ticket).then(
      (res) => res.data
    );
  }
);
