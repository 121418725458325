import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppBarElement } from "../elements/AppBar.element";
import { DrawerElement } from "../elements/Drawer.element";
import { useAppContext } from "../../contexts/AppContext";
import { useAppDispatch } from "../../../core/store/hooks";
// import { changeArea } from "../../../core/store/reducers/Port.reducer";

export const AppLayout: React.FC = ({ children }) => {
  const { drawerOnTop, drawerWidth } = useAppContext();
  // const dispatch = useAppDispatch()
  // const { areaId } = useParams()
  const currentTheme = useTheme();
  // useEffect(() => {
  //   if (areaId) dispatch(changeArea(areaId === "all" ? "" : areaId))
  // }, [areaId]);

  return (
    <Box
      component="main"
      className="app-layout flex w-full min-h-screen bg-fcolightblue"
    >
      <AppBarElement />
      <DrawerElement />
      <Box
        sx={{
          paddingLeft: drawerOnTop
            ? currentTheme.spacing(7)
            : drawerWidth + "px",
        }}
        className="app-content w-full flex flex-col pt-24 flex-grow"
      >
        {children}
        <Outlet />
      </Box>
    </Box>
  );
};
