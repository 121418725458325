import { BERTH_STATUS } from "@wattsonelements/front-fdk/dist/constants/berth.constants";
import { IBerthDetail, IBerthStats, IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { IMarinaVigie } from "@wattsonelements/front-fdk/dist/models/Vigie.models";
import { IPlug, IPlugDetail, IPowerUnit } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import { ITicket, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export type Order = "asc" | "desc" | undefined;

export interface BaseStateModule {
  list: any[];
  sort?: {
    order: Order;
    orderBy: string | undefined;
  };
  filters: {
    text: string;
    status: { [key: string]: boolean };
  };
  selected: any | null;
  selectedID?: UUID;
  legend?: any;
  listStatus: "" | "loading" | "error" | "success";
  firstLoading: boolean;
  selectedStatus: "" | "loading" | "error" | "success";
  config: ModuleConfig;
  stats?: any;
}

export interface ModuleConfigError {
  message: string;
  code: number;
}

export interface ModuleConfig {
  status?: {
    available: boolean;
    last_update?: string;
    error?: ModuleConfigError;
  };
  config: {
    enable?: boolean;
    reporting: boolean;
    refresh_delay: number; // secondes
  };
}

export interface BerthState extends BaseStateModule {
  list: IMarinaBerth[],
  selected: IBerthDetail | null,
  loading: boolean,
  filters: {
    text: string,
    status: { [key in BERTH_STATUS]: boolean }
  },
  stats?: IBerthStats
}

export enum VIGIE_STATUS {
  UNPROTECTED = "unprotected",
  // PREMIUM = 'premium',
  FREEMIUM = "freemium",
  LOCKED = "premium_locked",
  UNLOCKED = "premium_unlocked",
}

export interface VigieState extends BaseStateModule {
  list: IMarinaVigie[],
  selected: IMarinaVigie | null,
  filters: {
    text: string;
    status: { [key in VIGIE_STATUS]: boolean };
  };
}

export enum ELEC_STATUS {
  IN_USE = "inUse",
  NOT_IN_USE = "not_in_use",
  FAILURE = "failure"
}
export interface ElectricityState extends BaseStateModule {
  list: IPlug[],
  powerunitList: IPowerUnit[],
  selected: IPlug | IPlugDetail | null,
  selectedMarkerId: null | string,
  selectedPowerUnit: IPowerUnit | null
  filters: {
    text: string;
    status: { [key in ELEC_STATUS]: boolean };
  };
  stats?: {
    data: number[];
    labels: string[];
    percentage: number;
  };
}

export interface TicketState extends BaseStateModule {
  list: ITicket[],
  closed: ITicket[],
  loading: boolean,
  selected: ITicket | null,
  categories: ITicketCategory[]
  filters: {
    text: string;
    category: string;
    status: { [key: string]: boolean };
  };
  updating: UUID[];
}
