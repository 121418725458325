import { Box, Tabs, Tab, Button } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useModule } from "../../../core/services/Modules.service";
import { useAppSelector } from "../../../core/store/hooks";
import { useAppContext } from "../../contexts/AppContext";
import { TicketButton } from "../UX/Ticket.button";
import { NewTicketModal } from "./dashboard/Tickets/NewTicket.modal";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RouteTabs = [
  {
    url: "/admin/modules/berths",
    label: "menu.modules.berth",
    module: "berths",
  },
  {
    url: "/admin/modules/electricity",
    label: "menu.modules.electricity",
    module: "electricity",
  },
  {
    url: "/admin/modules/vigie",
    label: "menu.modules.vigie",
    module: "vigie",
  },
];

function replaceAreaUrl(tab: typeof RouteTabs, areaId: string): typeof RouteTabs {
  return tab.map((t: typeof RouteTabs[0]) => { return { ...t, url: t.url.replace("/modules", `/area/${areaId || "all"}/modules`) } })
}

// TODO : add responsive
// TODO : add config verification
export const TabMenu: FC = ({ children }) => {
  const [value, setValue] = useState<number | boolean>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { areaId } = useParams();
  const { t } = useTranslation("app");
  const { toggleTicketModal } = useAppContext();

  const [tabList, setTabList] = useState<any[]>([]);

  const port = useAppSelector((state) => state.ports.current);
  const { isModuleActivated } = useModule();

  const ticketCount = useAppSelector((state) => state.tickets.list.filter(tick => tick.status !== FDK.Constants.TicketConstants.TicketStatus.CLOSED && tick.status !== FDK.Constants.TicketConstants.TicketStatus.ARCHIVED).length);
  const loading = useAppSelector(
    (state) =>
      state.tickets.listStatus === "loading" && state.tickets.firstLoading
  );

  useEffect(() => {
    if (port) {
      setTabList(replaceAreaUrl(RouteTabs, areaId || "all").filter((tab) => isModuleActivated(tab.module)));
    }
  }, [port, location]);

  const openModal = () => {
    toggleTicketModal!();
  };

  useEffect(() => {
    const index = tabList.findIndex((r) => location.pathname.includes(r.url));
    if (index >= 0) {
      setValue(index);
    } else {
      setValue(false);
    }
  }, [location, tabList]);

  return (
    <Box className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 w-full">
      <Box className="order-last md:order-first justify-self-start flex justify-end flex-col">
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={() => { }}
          aria-label="dashboard tabs menu"
        >
          {tabList.map((tab, i) => (
            <Tab
              key={tab.url}
              onClick={(e) => {
                navigate(tab.url);
              }}
              label={t(tab.label)}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Box>
      <Box className="justify-self-center flex gap-x-3 justify-center items-center">
        <TicketButton loading={loading} count={ticketCount} create={false} />
        <TicketButton onClick={openModal} create={true} />
      </Box>
      <NewTicketModal />
    </Box>
  );
};
