import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";

export const getUserPort = createAsyncThunk("port/current", async () => {
  return FDK.Marina.PortApi.getUserPort().then((res) => {
    if (res && res.data && res.data.length > 0) {
      return res.data[0];
    }
    return null;
  });
});
