import { useLocation, useNavigate, useParams } from "react-router-dom";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { logoutAction, login } from "../store/reducers/Auth.reducer";
import {
  getAgentsManagersList,
  getUserProfile,
} from "../store/actions/User.actions";
import { getUserPort } from "../store/actions/Port.actions";
import {
  getTicketsCategories,
  getTicketsList,
} from "../store/actions/Ticket.actions";
import { usePeriodic } from "./Periodic.service";
import FDK from "@wattsonelements/front-fdk";
import { formatAreaId } from "../../_helpers/misc.helpers";
import { useQAContext } from "../../app/contexts/QAContext";

export const useAuth = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  let location = useLocation();
  const { API_URL } = useQAContext();

  const { areaId } = useParams();
  const { startPeriodic, clearAll } = usePeriodic();

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const logout = () => {
    if (isLoggedIn === false) { loggedOutNavigate(); return };
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    dispatch(logoutAction());
    loggedOutNavigate();
    clearAll();
  };

  const loggedOutNavigate = () => {
    if (
      !(
        location.pathname.includes("connexion") ||
        location.pathname.includes("reset-password") ||
        location.pathname.includes("forget-password")
      )
    ) {
      navigate(`/connexion`);
    }
  };
  const redirection = (isDefault = false) => {
    if (!isLoggedIn) {
      logout();
    } else if (isLoggedIn && isDefault) {
      navigate(`/admin/area/${areaId || "all"}/modules/berths`);
    }
  };

  const checkLogin = () => {
    const refresh = localStorage.getItem("refresh");
    const access = localStorage.getItem("access");
    let decoded = refresh && jwt_decode<JwtPayload>(refresh || "");
    if (
      refresh &&
      access &&
      decoded &&
      typeof decoded?.exp === "number" &&
      decoded?.exp! > Math.floor(Date.now() / 1000)
    ) {
      dispatch(login());
      return true;
    } else {
      logout();
      return false;
    }
  };

  const init = () => {
    if (isLoggedIn === "pending") return;
    if (!isLoggedIn) {
      logout();
    } else {
      FDK.Auth.refreshToken(localStorage.getItem("refresh") || "", API_URL).then(
        ({ data }) => {
          localStorage.setItem("access", data.access);
          localStorage.setItem("refresh", data.refresh);
          dispatch(getUserProfile());
          dispatch(getUserPort());
          dispatch(getTicketsCategories());
          // dispatch(getTicketsList());
          dispatch(getAgentsManagersList());
          startPeriodic("tickets-list", 60, () => {
            return dispatch(getTicketsList({ port_area: "" }));
          });
        },
        (err) => {
          logout();
        }
      );
    }
  };

  return { logout, redirection, checkLogin, init };
};
