import { Skeleton } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import { BERTH_STATUS } from "@wattsonelements/front-fdk/dist/constants/berth.constants";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface StatusChipProps {
  status: BERTH_STATUS | undefined;
}

export const StatusChip: FC<StatusChipProps> = ({
  status,
}: StatusChipProps) => {
  const { t } = useTranslation("berth");
  const [color, setColor] = useState({ bg: "#5f5f5f", text: "#fff" });

  useEffect(() => {
    switch (status) {
      case FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABILITY_TO_CONFIRM:
      case FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_TO_CONFIRM:
        setColor({ bg: "#be3a2d", text: "#fff" });
        break;
      case FDK.Constants.BerthConstants.BERTH_STATUS.AVAILABLE:
        setColor({ bg: "#5ab19b", text: "#fff" });
        break;
      case FDK.Constants.BerthConstants.BERTH_STATUS.UNAVAILABLE:
      case FDK.Constants.BerthConstants.BERTH_STATUS.ERROR:
      case FDK.Constants.BerthConstants.BERTH_STATUS.MOORING_IDDLE:
        setColor({ bg: "#5f5f5f", text: "#fff" });
        break;
      default:
        break;
    }
  }, [status]);
  return (
    <div
      style={{ backgroundColor: color.bg, color: color.text }}
      className="rounded-full py-2 px-4"
    >
      {status ? (
        t(`status.${status}`)
      ) : (
        <Skeleton width={150} height={30} variant="text" />
      )}
    </div>
  );
};
