import { Navigate, useRoutes } from "react-router-dom";
import { BerthDetail } from "../app/components/elements/dashboard/Berth/BerthDetail.element";
import { BerthTable } from "../app/components/elements/dashboard/Berth/BerthTable.element";
import { ElecDetail } from "../app/components/elements/dashboard/Electricity/ElecDetail.element";
import { ElecTable } from "../app/components/elements/dashboard/Electricity/ElecTable.element";
import { TicketDetail } from "../app/components/elements/dashboard/Tickets/TicketDetail.element";
import { TicketTable } from "../app/components/elements/dashboard/Tickets/TicketTable.element";
import { AppLayout } from "../app/components/layouts/App.layout";
import { ModuleLayout } from "../app/components/layouts/Module.layout";
import { AssistancePage } from "../app/components/_pages/Assistance/Assistance.page";
import { LoginPage } from "../app/components/_pages/Auth/Login.page";
import { LostPasswordPage } from "../app/components/_pages/Auth/LostPassword.page";
import { ResetPasswordPage } from "../app/components/_pages/Auth/ResetPassword.page";
import { NewsCmsPage } from "../app/components/_pages/CMS/News.page";
import { UsersCmsPage } from "../app/components/_pages/CMS/Users.page";
import { BerthModulePage } from "../app/components/_pages/Dashboard/Berth.page";
import { ElectricityModulePage } from "../app/components/_pages/Dashboard/Electricity.page";
import { TicketsModulePage } from "../app/components/_pages/Dashboard/Tickets.page";
import { VigieModulePage } from "../app/components/_pages/Dashboard/Vigie.page";
import { ReportingPage } from "../app/components/_pages/Reporting/Reporting.page";
import { useAuth } from "./services/Auth.service";

const AuthRedirection = ({
  children,
  isDefault = false,
}: {
  children?: JSX.Element;
  isDefault?: boolean;
  routeDefault?: string;
}) => {
  const { redirection } = useAuth();
  redirection(isDefault);
  return <>{children}</>;
};

export const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <AuthRedirection isDefault={true} />,
    },
    {
      path: "/connexion",
      index: true,
      element: (
        <AuthRedirection isDefault={true}>
          <LoginPage />
        </AuthRedirection>
      ),
    },
    {
      path: "/reset-password",
      element: <ResetPasswordPage />,
    },
    {
      path: "/forget-password",
      element: <LostPasswordPage />,
    },
    {
      path: "/admin",
      // add auth islogged
      element: (
        <AuthRedirection>
          <AppLayout />
        </AuthRedirection>
      ),
      children: [
        {
          path: "/admin/modules*",
          // add verifications config
          element: <Navigate to="/admin/area/all/modules/berths" replace />
        },
        {
          path: "/admin/area/:areaId/modules",
          // add verifications config
          element: <ModuleLayout />,
          children: [
            {
              path: "/admin/area/:areaId/modules/berths",
              element: <BerthModulePage />,
              children: [
                {
                  path: "/admin/area/:areaId/modules/berths",
                  element: <BerthTable />,
                },
                {
                  path: "/admin/area/:areaId/modules/berths/:berthId",
                  element: <BerthDetail />,
                },
              ],
            },
            {
              path: "/admin/area/:areaId/modules/electricity",
              element:  <ElectricityModulePage />,
              children: [
                {
                  path: "/admin/area/:areaId/modules/electricity",
                  element: <ElecTable />,
                },
                {
                  path: "/admin/area/:areaId/modules/electricity/:id",
                  element: <ElecDetail />,
                },
              ],
            },
            {
              path: "/admin/area/:areaId/modules/vigie",
              element: <VigieModulePage />,
            },
            {
              path: "/admin/area/:areaId/modules/tickets",
              element: <TicketsModulePage />,
              children: [
                {
                  path: "/admin/area/:areaId/modules/tickets",
                  element: <TicketTable />,
                },
                {
                  path: "/admin/area/:areaId/modules/tickets/archived",
                  element: <TicketTable />,
                },
                {
                  path: "/admin/area/:areaId/modules/tickets/:id",
                  element: <TicketDetail />,
                },
              ],
            },
          ],
        },
        {
          path: "/admin/reporting",
          element: <Navigate to="/admin/reporting/berths" replace={true} />,
          children: [],
        },
        {
          path: "/admin/reporting/:slug",
          element: <ReportingPage />,
          children: [],
        },
        {
          path: "/admin/help",
          element: <AssistancePage />,
        },
        {
          path: "/admin/cms",
          children: [
            {
              path: "/admin/cms/news",
              element: <NewsCmsPage />,
            },
            {
              path: "/admin/cms/users",
              element: <UsersCmsPage />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <AuthRedirection isDefault={true} />,
    },
  ]);
  return routes;
};
