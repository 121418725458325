import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import { TicketStatus } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateTicket } from "../../../../../core/store/actions/Ticket.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";

interface SelectTicketStatusProps {
  ticket: ITicket;
  displayLabel?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}
export const SelectTicketStatus = (props: SelectTicketStatusProps) => {
  const { ticket, displayLabel = true } = props;
  const { t } = useTranslation("ticket");
  const dispatch = useAppDispatch();
  const updating = useAppSelector((state) => state.tickets.updating);
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    setIsUpdating(updating.includes(ticket.id));
  }, [updating, ticket]);
  const [availableStatus, setAvailableStatus] = useState<TicketStatus[]>([]);
  useEffect(() => {
    switch (ticket.status) {
      case TicketStatus.OPEN:
        setAvailableStatus([ticket.status, TicketStatus.CLOSED]);
        break;

      case TicketStatus.ONGOING:
        setAvailableStatus([ticket.status, TicketStatus.CLOSED]);
        break;

      case TicketStatus.CLOSED:
        setAvailableStatus([
          ticket.status,
          // TicketStatus.ARCHIVED,
          TicketStatus.ONGOING,
        ]);
        break;

      default:
        setAvailableStatus([ticket.status]);
        break;
    }
  }, [ticket]);

  const handleChange = (e: SelectChangeEvent<TicketStatus>) => {
    e.stopPropagation();
    e.preventDefault();
    const value = e.target.value as TicketStatus;
    if (ticket.status !== value)
      dispatch(
        updateTicket({ id: ticket.id, ticket: { ...ticket, status: value } })
      );
  };

  return (
    <FormControl
      color="secondary"
      sx={{ m: 1, minWidth: 120, position: "relative" }}
      size="small"
    >
      {displayLabel && (
        <InputLabel id="demo-select-small">
          {t("table.headers.status")}
        </InputLabel>
      )}

      {isUpdating && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            background: "#1f34572e",
            zIndex: 10,
            width: "100%",
            transform: "translateY(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(2px)",
            padding: "10px",
          }}
        >
          <CircularProgress thickness={5} color="primary" size={20} />
        </Box>
      )}
      <Select
        onOpen={props.onOpen ? props.onOpen : () => {}}
        onClose={props.onClose ? props.onClose : () => {}}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={ticket.status}
        // label="status"
        onChange={handleChange}
        sx={{ position: "relative" }}
      >
        {/* hack to prevent MUI warning on non possible value */}
        {availableStatus.length < 1 && (
          <MenuItem value={ticket.status}></MenuItem>
        )}
        {/* else */}
        {availableStatus.map((s) => (
          <MenuItem key={ticket.id + s} value={s}>
            {t(`status.${s}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
