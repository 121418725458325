import { Button } from "@mui/material";
import { AxiosResponse } from "axios";
import { FC, MouseEvent } from "react";
interface DlFileButtonProps {
  disabled?: boolean;
  children?: React.ReactNode;
  request: () => Promise<AxiosResponse<any, any>>;
}

export const DlFileButton: FC<DlFileButtonProps> = (
  props: DlFileButtonProps
) => {
  const { disabled = false, children, request } = props;

  const download = (res: AxiosResponse<any, any>) => {
    const contentDisposition = res.headers["content-disposition"];
    //.get("content-disposition");
    const excelFile = res.data;
    const url = window.URL.createObjectURL(excelFile);
    const a = document.createElement("a");
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let filename = `export.xlsx`;
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
  return (
    <Button
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        request().then((res) => download(res));
      }}
      disabled={disabled}
      variant="contained"
      color="secondary"
    >
      {children}
    </Button>
  );
};
