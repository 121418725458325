import {
  Avatar,
  Badge,
  Box,
  Button,
  Modal,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useAppContext } from "../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../core/services/Auth.service";
import { UserEdit } from "./UserEdit.element";
import { useAppSelector } from "../../../core/store/hooks";
import useConfirm from "../../../_helpers/useConfirm";

export const UserMenu: React.FC = ({ children }) => {
  const { logout } = useAuth();
  const { t } = useTranslation("app");
  const { confirm } = useConfirm();
  const user = useAppSelector((state) => state.users.profile);
  const { drawerOpened } = useAppContext();
  const [anchorModal, setAnchorModal] = useState<null | HTMLElement>(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(
    null
  );
  const userMenuOpened = Boolean(anchorUserMenu);
  // const open = Boolean(anchorUserMenu);
  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };
  const closeUserMenu = () => {
    setAnchorUserMenu(null);
  };
  const modalOpened = Boolean(anchorModal);
  const openModal = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorUserMenu(null);
    setAnchorModal(event.currentTarget);
  };
  const closeModal = () => {
    setAnchorModal(null);
  };
  const handleLogout = async () => {
    closeUserMenu();
    if (!await confirm(t("logoutConfirm")))
      return;
    logout();
  }

  return (
    <>
      <Button
        className="flex gap-x-3 normal-case"
        id="basic-button"
        aria-controls={drawerOpened ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={drawerOpened ? "true" : undefined}
        onClick={openUserMenu}
      >
        <Avatar alt="Profile" src={user?.picture} />
        <Typography color="secondary" noWrap>
          {user?.first_name} {user?.last_name}
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <Modal
        className="overflow-y-auto"
        open={modalOpened}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 shadow-md" sx={{
          bgcolor: "background.paper"
        }}>
          <UserEdit />
        </Box>
      </Modal>
      <Menu
        id="basic-menu"
        anchorEl={anchorUserMenu}
        open={userMenuOpened}
        onClose={closeUserMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className="flex gap-x-3" onClick={openModal}>
          <PersonOutlineIcon />
          <span>{t("updateProfile")}</span>
        </MenuItem>
        <MenuItem
          className="flex gap-x-3"
          onClick={handleLogout}
        >
          <PowerSettingsNewIcon />
          <span>{t("logout")}</span>
        </MenuItem>
      </Menu>
    </>
  );
};
