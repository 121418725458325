import { Box, Button, Table } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePeriodic } from "../../../../core/services/Periodic.service";
import { useVigieService } from "../../../../core/services/Vigie.service";
import { getVigieList } from "../../../../core/store/actions/Vigie.actions";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import {
  changeSort,
  updateFilterWithoutSaving,
  setSelected as setSelectedVigie,
  updateFilter,
  updateStatus,
  setFirstLoading,
} from "../../../../core/store/reducers/Vigie.reducer";
import { getFilteredVigieList } from "../../../../core/store/selectors/Module.selector";
import { getFirstElement } from "../../../../_helpers/array.helpers";
import { MapType } from "../../../../_helpers/map.helper";
import { GoToTicketBtn } from "../../elements/dashboard/GoToTicketBtn.element";
import { Legend } from "../../elements/dashboard/Legend";
import { ModuleTable } from "../../elements/dashboard/Table";
import { TableTop } from "../../elements/dashboard/TableTop";
import { MapBoxLayer } from "../../elements/MapBoxLayer.element";
import { DashboardLayout } from "../../layouts/Dashboard.layout";
import { DlFileButton } from "../../UX/DlFile.button";
import { VIGIE_STATUS } from "../../../../core/models/module.models";
import { Geojson } from "../../../../core/models/map.models";
import { IMarinaVigie } from "@wattsonelements/front-fdk/dist/models/Vigie.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import FDK from "@wattsonelements/front-fdk";
import { useNavigate, useParams } from "react-router-dom";
import { formatAreaId, generateAreaUrl } from "../../../../_helpers/misc.helpers";
import { usePortService } from "../../../../core/services/Port.service";

export const VigieModulePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("vigie");
  const { startPeriodic, stopPeriodic } = usePeriodic();
  const { areaId } = useParams();
  const navigate = useNavigate();
  const port = useAppSelector((state) => state.ports.current);
  const config = useAppSelector((state) => state.vigie.config);
  const selectedVigie = useAppSelector((state) => state.vigie.selected);
  const selectedVigieId = useAppSelector((state) => state.vigie.selected?.id);
  const { generateAreasStatsGeoJson } = usePortService();
  const datas = useAppSelector(getFilteredVigieList);
  const delay = config.config.refresh_delay;
  const listLoading = useAppSelector((state) => state.vigie.firstLoading);
  const filters = useAppSelector((state) => state.vigie.filters);
  const order = useAppSelector((state) => state.vigie.sort?.order);
  const orderBy = useAppSelector((state) => state.vigie.sort?.orderBy);
  const [selected, setSelected] = useState<IMarinaVigie[]>([]);
  const [loading, setLoading] = useState(true);
  const { generateVigieGeoJson } = useVigieService();
  const [geojson, setGeoJson] = useState<Geojson>(generateVigieGeoJson([]));
  const mapRef = useRef<any>();

  const clickOnMarker = (vigieId: UUID) => {
    const vigie = datas.find((d) => d.id === vigieId);
    if (vigie) {
      dispatch(setSelectedVigie(vigie));
    }
  };
  const clickOnArea = (id: UUID) => {
    navigate(generateAreaUrl(id))
  };

  const sortHandler = (key: string) => {
    let tmpOrder;
    if (order === "asc")
      tmpOrder = "desc";
    else
      tmpOrder = "asc";
    // if (order === undefined) {
    //   tmpOrder = "asc";
    // } else if (order === "asc") {
    //   tmpOrder = "desc";
    // } else {
    //   tmpOrder = undefined;
    // }
    dispatch(changeSort({ order: tmpOrder, orderBy: key }));
  };

  useEffect(() => {
    if (loading || listLoading || !mapRef.current) return
    if (!selectedVigie)
      setTimeout(() => mapRef.current.fitTheMap(), 500);
    else
      setTimeout(() => mapRef.current.flyToSelectedMarker(selectedVigie.id), 500);
  }, [selectedVigie, loading, listLoading, mapRef.current]);

  useEffect(() => {
    if (!port) return;
    if (datas) {
      (formatAreaId(areaId) === "" && port.areas.length > 1) ?
        setGeoJson(generateAreasStatsGeoJson(port.areas, MapType.VIGIE))
        : setGeoJson(generateVigieGeoJson(datas))
      setLoading(false);
    }
  }, [datas, port]);

  useEffect(() => {
    dispatch(setFirstLoading(true))
    startPeriodic("vigie-list", delay, () => {
      return dispatch(getVigieList({ port_area: formatAreaId(areaId) }));
    });
    return () => {
      stopPeriodic("vigie-list");
      dispatch(setSelectedVigie(null));
      dispatch(updateFilterWithoutSaving({ text: "" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  const onClickRow = (event: React.MouseEvent, vigie: IMarinaVigie) => {
    if (selectedVigie && selectedVigie.id === vigie.id) {
      dispatch(setSelectedVigie(null));
    } else {
      if (port && port.areas.length > 1)
        navigate(`/admin/area/${vigie.port_area}/modules/vigie`);
      else
        navigate(`/admin/area/all/modules/vigie`);
      dispatch(setSelectedVigie(vigie));
    }
  };

  const legend = [
    {
      legends: [
        {
          label: t("legend.unprotected"),
          key: VIGIE_STATUS.UNPROTECTED,
          icon: {
            src: require(`../../../../assets/img/icons/vigie/legend/unprotected.png`),
          },
        },
        {
          label: t("legend.freemium"),
          key: VIGIE_STATUS.FREEMIUM,
          icon: {
            src: require(`../../../../assets/img/icons/vigie/legend/freemium.png`),
          },
        },
      ],
    },
    {
      legends: [
        {
          label: t("legend.vigie"),
          key: "",
          icon: {
            src: require(`../../../../assets/img/icons/vigie/legend/premium.png`),
          },
          children: [
            {
              label: t("legend.on"),
              key: VIGIE_STATUS.LOCKED,
              icon: {
                src: require(`../../../../assets/img/icons/vigie/legend/locked.png`),
              },
            },
            {
              label: t("legend.off"),
              key: VIGIE_STATUS.UNLOCKED,
              icon: {
                src: require(`../../../../assets/img/icons/vigie/legend/unlocked.png`),
              },
            },
          ],
        },
      ],
    },
  ];

  const headers = [
    {
      label: t("table.headers.boat"),
      key: "boat",
      format: (data: IMarinaVigie) => data.name,
      sort: true,
      cellClassName: "cursor-pointer",
      compareValue: (data: IMarinaVigie) => data.name,
    },
    {
      label: t("table.headers.name"),
      key: "",
      format: (data: IMarinaVigie) => {
        const owner = getFirstElement(data.owners);
        return owner ? `${owner.first_name} ${owner.last_name}` : "- -";
      },
      sort: true,
      cellClassName: "cursor-pointer",
      compareValue: (data: IMarinaVigie) => {
        const owner = getFirstElement(data.owners);
        return owner ? `${owner.first_name} ${owner.last_name}` : "- -";
      },
    },
    {
      label: t("table.headers.berth"),
      key: "",
      cellClassName: "cursor-pointer",
      format: (data: IMarinaVigie) => {
        const berth = getFirstElement(data.spots);
        return berth ? `${berth.name}` : "- -";
      },
      sort: true,
      compareValue: (data: IMarinaVigie) => {
        const berth = getFirstElement(data.spots);
        return berth ? `${berth.name}` : "- -";
      },
    },
    {
      label: t("table.headers.contract"),
      key: "",
      cellClassName: "cursor-pointer",
      format: (data: IMarinaVigie) => {
        const falcoLevel =
          data.devices.length === 0 ? "unprotected" : data.falco_level;
        const icon = require(`../../../../assets/img/icons/vigie/legend/${falcoLevel}.png`);
        return (
          <img
            className="m-auto"
            style={{ width: "30px" }}
            alt={falcoLevel}
            src={icon}
          />
        );
      },
      sort: true,
      compareValue: (data: IMarinaVigie) => data.falco_level,
    },

    {
      label: t("table.headers.alarm"),
      key: "",
      cellClassName: "cursor-pointer",
      format: (data: IMarinaVigie) => {
        const name = data.locked ? "locked" : "unlocked";
        const icon = require(`../../../../assets/img/icons/vigie/legend/${name}.png`);
        if (data.falco_level === "premium") {
          return (
            <img
              className="m-auto"
              style={{ width: "30px" }}
              alt={name}
              src={icon}
            />
          );
        } else
          return <></>;
      },
      sort: true,
      compareValue: (data: IMarinaVigie) => (data.locked ? 1 : 0),
    },
    {
      label: t("table.headers.tickets"),
      key: "",
      format: (data: IMarinaVigie) => {
        const ticket = getFirstElement(data.tickets) || "";
        return ticket ? (
          <GoToTicketBtn nbTickets={data.tickets.length} ticketId={ticket.id} />
        ) : (
          ""
        );
      },
      sort: true,
      compareValue: (data: IMarinaVigie) => data.tickets.length,
    },
  ];
  return (
    <DashboardLayout
      top={
        <Legend
          legendStatus={filters.status}
          updateLegend={(checked: boolean, key: string) => {
            dispatch(updateStatus({ [key]: checked }));
          }}
          legends={legend}
        />
      }
    >
      <Box className="flex-col flex flex-1 flex-grow">
        <TableTop
          datas={datas}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            dispatch(updateFilterWithoutSaving({ text: event.target.value }));
          }}
          buttons={
            <>
              <DlFileButton
                request={() =>
                  FDK.Marina.VigieModule.getExport(selected.map((s) => s.id)) as any
                }
                disabled={selected.length === 0}
              >
                {t("btns.export")}
              </DlFileButton>
            </>
          }
        />
        <ModuleTable
          selectedKey={selectedVigie?.id}
          onChangeSelection={setSelected}
          datas={datas}
          headers={headers}
          onClickRow={onClickRow}
          order={order}
          orderBy={orderBy}
          onSort={sortHandler}
          isLoading={listLoading}
        />
      </Box>
      <MapBoxLayer
        ref={mapRef}
        loading={loading || listLoading}
        mapType={MapType.VIGIE}
        selectedMarkerId={selectedVigieId ? selectedVigieId : null}
        geojson={geojson}
        clickOnMarker={
          !port ?
            () => { }
            : (formatAreaId(areaId) === "" && port.areas.length > 1) ?
              clickOnArea
              : clickOnMarker}
      />
    </DashboardLayout>
  );
};
