import { Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";
import { useDropzone } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form';
import { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { loadAsBase64 } from '../../../_helpers/image.helpers';

type ImageUploaderProps = {
  thumb: boolean;
  form?: UseFormReturn | any;
  name: string;
  simpleButton?: boolean;
  multiple?: boolean;
  label?: string | null;
  displayLabel?: boolean;
  addBtn?: string | JSX.Element | null;
  delBtn?: string | null;
  deleteButton?: boolean;
  currentImg: string | undefined;
  onChange?: (files: File[]) => void;
  preview: boolean;
}
export const ImageUploader = forwardRef((props: ImageUploaderProps, ref: any) => {
  const {
    thumb,
    simpleButton = false,
    form = null,
    name,
    multiple = false,
    displayLabel = true,
    label = null,
    addBtn = null,
    delBtn = null,
    deleteButton = false,
    currentImg,
    onChange = null,
    preview
  } = props;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [localImgs, setLocalImgs] = useState(currentImg && currentImg != "" ? [currentImg] : []);
  useEffect(() => {
    if (typeof currentImg === "string")
      setLocalImgs([currentImg])
  }, [currentImg])
  const handleDelete = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (typeof onChange === "function")
      onChange([]);
    setLocalImgs([]);
    if (form)
      form.setValue(name, multiple ? [] : "");
    return false;
  }
  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
      initialQuality: 0.8
    };
    let compressedFiles: File[] = [];
    let base64Files: string[] = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      try {
        let compressedFile = await imageCompression(acceptedFiles[i], options);
        compressedFiles.push(new File([compressedFile], compressedFile.name))
        base64Files.push(await loadAsBase64(compressedFile));
      } catch (error) {
        if (form)
          form.setError(name, { type: 'custom', message: t("form.imageInput.imageError2") });
        console.log(error);
      }
    }
    if (form)
      form.setValue(name, multiple ? compressedFiles : compressedFiles[0]);
    setLocalImgs(base64Files);

    if (typeof onChange === "function")
      onChange(compressedFiles);
    setLoading(false);
  }, [])
  useImperativeHandle(ref, () => ({
    reset() {
      handleDelete(null);
    },
  }))
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpg, image/jpeg, image/png', multiple })
  return (
    <Box
      {...getRootProps({
        className: simpleButton ?
          ""
          :
          'p-3 hover:border-fcosecondaryhover border-dashed border-gray-300 border-2 flex flex-col w-full items-center gap-y-2 justify-center'
          + (isDragActive && " border-fcosecondaryhover")
      })}>
      {!simpleButton && displayLabel && <p className="text-fcomain text-opacity-40">{label ? label : multiple ? t('form.imageInput.labelMultiple') : t('form.imageInput.label')}</p>}
      <Box className="flex items-center justif-center gap-x-2">
        {loading ?
          <CircularProgress size={20} color="secondary" /> :
          addBtn && typeof addBtn !== "string" ?
            <IconButton className="shadow-md flex-auto">
              {addBtn}
            </IconButton>
            :
            <Button
              component="span"
              variant="contained">
              {addBtn ? addBtn : t('form.imageInput.addBtn')}
              <AddPhotoAlternateIcon />
            </Button>
        }
        {deleteButton && localImgs.length > 0 && localImgs[0] !== "" &&
          <Button
            onClick={handleDelete}
            variant="outlined"
            color="error">
            {delBtn ? delBtn : t('form.imageInput.delBtn')}
          </Button>}
      </Box>
      {!simpleButton &&
        <Box className="flex gap-2 flex-wrap">
          {preview && localImgs && localImgs.length > 0 &&
            localImgs.map((img, i) => img != "" && <Box key={"img-" + i}
              className={thumb || localImgs.length > 1 ? localImgs.length > 1 ? "w-auto max-h-20" : "w-auto max-h-24" : "w-auto max-h-92"}
              component="img"
              alt="user picture"
              src={img}
            />)
          }
        </Box>}
      {form ?
        <input
          multiple={multiple}
          className="hidden"
          accept="image/*"
          {...form.register(name, {
          })}
          id={name} type="file" />
        :
        <input
          multiple={multiple}
          className="hidden"
          accept="image/*"
          id={name} type="file" />
      }
      <input
        {...getInputProps()} />
    </Box>

  )
})
