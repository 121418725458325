import { Box, Button, Chip, CircularProgress } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import { IPlug } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import { IIntervention } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useElectricityService } from "../../../../../core/services/Electricity.service";
import { useNavigate, useParams } from "react-router-dom";
import { usePeriodic } from "../../../../../core/services/Periodic.service";
import { createTicket } from "../../../../../core/store/actions/Ticket.actions";
import { useAppDispatch, useAppSelector } from "../../../../../core/store/hooks";
import { changeSort, updateFilterWithoutSaving, setSelected as setSelectedPlug, setSelectedPowerUnit, setSelectedMarkerId, } from "../../../../../core/store/reducers/Electricity.reducer";
import { getFilteredPlugList } from "../../../../../core/store/selectors/Module.selector";
import { getFirstElement } from "../../../../../_helpers/array.helpers";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import { DlFileButton } from "../../../UX/DlFile.button";
import { GoToTicketBtn } from "../GoToTicketBtn.element";
import { ModuleTable } from "../Table";
import { TableTop } from "../TableTop";

export const ElecTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("electricity");
  const { areaId } = useParams();
  const { restartPeriodic } = usePeriodic();
  const navigate = useNavigate();
  const port = useAppSelector((state) => state.ports.current);
  const listLoading = useAppSelector((state) => state.electricity.firstLoading);
  const list = useAppSelector(getFilteredPlugList);
  const selectedPlug = useAppSelector((state) => state.electricity.selected);
  const orderBy = useAppSelector((state) => state.electricity.sort?.orderBy);
  const order = useAppSelector((state) => state.electricity.sort?.order);
  const currentPort = useAppSelector((state) => state.ports.current);
  const selectedPowerUnit = useAppSelector((state) => state.electricity.selectedPowerUnit);

  const { getPlugIconName } = useElectricityService()


  const filters = useAppSelector((state) => state.electricity.filters)
  console.log("searchValue", filters.text)
  const parentCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "g_electricity"
  );
  const childCateg = useAppSelector((state) => state.tickets.categories).find(
    (cat: any) => cat.slug === "electricity"
  );

  const { setAlertMessage } = useAppContext();

  const [creatingTicket, setCreatingTicket] = useState(false);
  const [selected, setSelected] = useState<IPlug[]>([]);

  const tableRef = useRef<any>();


  const canCreateTicket = () => {
    return selected.length > 0;
    let tmp = selected.filter(
      (sel) =>
        sel.tickets.length === 0 ||
        sel.tickets.find((tic: any) => tic.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED)
    );
    return tmp.length > 0;
    // const result = selected.every(
    //   (plug) =>
    //     !plug.ticket &&
    //     (plug.global_consumption.high_consumption_12_hours ||
    //       plug.global_consumption.high_consumption_now)
    // );
    // return result && selected.length > 0;
  };

  const createTickets = async (e: any) => {
    setCreatingTicket(true);
    let errors = 0;
    for (let i in selected) {
      try {
        let ticketIntervention: IIntervention = {
          intervention_assignee: [],
          intervention_step: [
            {
              intervention_step_attachment: [],
              intervention_step_note: [],
            },
          ],
        };
        const defaultValues = {
          ticket_intervention: [ticketIntervention],
          // category: isOverConso(selected[i]) ? childCateg!.id : parentCateg!.id,
          category: childCateg!.id,
          spot_id: selected[i].spot_id ? selected[i].spot_id : undefined,
          plug_id: selected[i].id,
          // plug: { id: selected[i].id, name: selected[i].name },
          boat: undefined,
          port: currentPort?.id,
          priority: FDK.Constants.TicketConstants.TicketPriority.NORMAL,
          description: "",
        };
        let res = await dispatch(createTicket(defaultValues));
        if (res.type === "ticket/create/rejected") {
          errors++;
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("ticketCreationError", { ns: "ticket" }),
          });
        }
      } catch (e) {
        errors++;
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketCreationError", { ns: "ticket" }),
        });
      }
    }
    if (selected.length > errors) {
      restartPeriodic("elec-list");
      if (tableRef.current) tableRef.current.resetSelected();
      setAlertMessage!({
        type: AlertType.SUCCESS,
        message: t("successfullyCreated", { ns: "ticket" }),
      });
    }
    setCreatingTicket(false);
  };

  const sortHandler = (key: string) => {
    let tmpOrder;
    if (order === "asc")
      tmpOrder = "desc";
    else
      tmpOrder = "asc";
    // if (order === undefined) {
    //   tmpOrder = "asc";
    // } else if (order === "asc") {
    //   tmpOrder = "desc";
    // } else {
    //   tmpOrder = undefined;
    // }
    dispatch(changeSort({ order: tmpOrder, orderBy: key }));
  };

  const onClickRow = (event: React.MouseEvent, plug: IPlug) => {
    if (selectedPlug && selectedPlug.id === plug.id) {
      dispatch(setSelectedPlug(null));
    } else {
      dispatch(setSelectedPlug(plug));
      if (port && port.areas.length > 1)
        navigate(`/admin/area/${plug.port_area}/modules/electricity/${plug.id}`);
      else
        navigate(`/admin/area/all/modules/electricity/${plug.id}`);
    }
  };

  const handleDeletePowerUnit = () => {
    dispatch(setSelectedPowerUnit(null))
    dispatch(setSelectedMarkerId(null))
  }

  const headers = [
    {
      label: t("table.headers.powerunit"),
      key: "powerunit",
      sort: true,
      cellClassName: "cursor-pointer",
      format: (data: IPlug) => data?.powerunit?.name,
      compareValue: (data: IPlug) => data?.powerunit?.name,
    },
    {
      label: t("table.headers.plug"),
      key: "plug",
      sort: true,
      cellClassName: "cursor-pointer",
      format: (data: IPlug) =>
        <div className="flex items-center gap-2 ">
          <span className="min-w-[70px]">{data?.name} </span>
          <img className="block w-6" alt='plug-status' src={require(`../../../../../assets/img/icons/electricity/legend/${getPlugIconName(data)}.svg`)} />
        </div>,
      compareValue: (data: IPlug) => data?.name,
    },

    {
      label: t("table.headers.berth"),
      key: "berth",
      sort: true,
      cellClassName: "cursor-pointer",
      format: (data: IPlug) => data?.spot_name,
      compareValue: (data: IPlug) => data?.spot_name,
    },
    {
      label: t("table.headers.ongoing"),
      key: "ongoing",
      sort: true,
      cellClassName: "cursor-pointer",
      format: (data: IPlug) => {
        if (data?.global_consumption?.now === 0) return
        return `${data?.global_consumption?.now} ${data?.global_consumption?.unit}`
      },
      compareValue: (data: IPlug) => data?.global_consumption?.now,
    },
    {
      label: t("table.headers.twelve"),
      key: "conso_twelve",
      sort: true,
      cellClassName: "cursor-pointer",
      format: (data: IPlug) => {
        if (!data?.global_consumption?.twelve) return <></>
        return (
          <Chip label={`${data?.global_consumption.twelve} ${data?.global_consumption.unit}`} />
        )
      },
      compareValue: (data: IPlug) => data?.global_consumption?.twelve,
    },

    // {
    //   label: t("table.headers.spike12h"),
    //   key: "spike12h",
    //   sort: true,
    //   cellClassName: "cursor-pointer",
    //   format: (data: IPlug) => data?.global_consumption?.max_12_hours + " A",
    //   compareValue: (data: IPlug) => data?.global_consumption?.max_12_hours,
    // },
    {
      label: t("table.headers.tickets"),
      key: "tickets",
      sort: true,
      format: (data: IPlug) => {
        if (!data.tickets) return "";
        const el = getFirstElement(data.tickets) || "";
        return el.id ? (
          <GoToTicketBtn nbTickets={data.tickets.length} ticketId={el.id} />
        ) : (
          ""
        );
      },
      compareValue: (data: IPlug) => data?.tickets.length,
    },
  ];

  return (
    <>
      <TableTop
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          dispatch(updateFilterWithoutSaving({ text: event.target.value }));
        }}
        searchValue={filters.text}
        filters={selectedPowerUnit ?
          <Chip color="secondary" label={`${t('powerunit')} ${selectedPowerUnit.name}`} onDelete={handleDeletePowerUnit} /> : <></>
        }
        buttons={
          <>
            {creatingTicket ? (
              <CircularProgress color={"info"} />
            ) : (
              <Button
                disabled={!canCreateTicket()}
                onClick={createTickets}
                variant="contained"
                color="secondary"
              >
                {selected.filter(
                  (sel) =>
                    sel.tickets.length === 0 ||
                    sel.tickets.find(
                      (tic: any) => tic.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED
                    )
                ).length > 1
                  ? t("btns.createMultiple")
                  : t("btns.create")}
              </Button>
            )}
            <DlFileButton
              request={() =>
                FDK.Marina.ElectricityModule.getExport(selected.map((s) => s.id)) as any
              }
              disabled={selected.length === 0}
            >
              {t("btns.export")}
            </DlFileButton>
          </>
        }
      />
      <ModuleTable
        ref={tableRef}
        onChangeSelection={setSelected}
        headers={headers}
        datas={list}
        isLoading={listLoading}
        selectedKey={selectedPlug?.id}
        onClickRow={onClickRow}
        order={order}
        orderBy={orderBy}
        onSort={sortHandler}
      />

    </>
  )
}