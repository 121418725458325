import { createContext, FC, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export enum AlertType {
  CONFIRM,
  ERROR,
  SUCCESS,
  CLEAR,
}

type AlertMessage = {
  message: string;
  type: AlertType;
};

interface ListPeriodic {
  [key: string]: {
    delay: number;
    isActive: boolean;
    request: () => Promise<any>;
    timeoutId: NodeJS.Timer | null;
    requireLogin: boolean;
    retry: number;
  };
}

interface IAppContext {
  drawerOpened: boolean;
  drawerWidth: number;
  drawerOnTop: boolean;
  alertMessage: AlertMessage;
  appLoading: boolean;
  setAppLoading?: (ldg: boolean) => void;
  periodics: ListPeriodic;
  ticketModalOpened: boolean;
  toggleDrawer?: () => void;
  setAlertMessage?: (msg: AlertMessage) => void;
  setPeriodics?: (periodic: ListPeriodic) => void;
  toggleTicketModal?: () => void;
}

const defaultState = {
  drawerOpened: false,
  drawerWidth: 260,
  drawerOnTop: true,
  appLoading: false,
  alertMessage: {
    type: AlertType.CLEAR,
    message: "",
  },
  periodics: {},
  ticketModalOpened: false
};

export const AppContext = createContext<IAppContext>(defaultState);

export const AppProvider: FC = ({ children }) => {
  const location = useLocation();
  const [drawerOpened, setDrawerOpened] = useState(defaultState.drawerOpened);
  const [drawerWidth, setDrawerWidth] = useState(defaultState.drawerWidth);
  const [drawerOnTop, setDrawerOnTop] = useState(defaultState.drawerOnTop);
  const [alertMessage, setAlertMsg] = useState(defaultState.alertMessage);
  const [appLoading, setAppLoadg] = useState(defaultState.appLoading);

  const [periodics, setPeriodics] = useState(defaultState.periodics);

  const [ticketModalOpened, setTicketModalOpened] = useState(defaultState.ticketModalOpened);

  const toggleDrawer = () => {
    if (!location.pathname.match("/reporting")) setDrawerOpened(!drawerOpened);
  };

  const toggleTicketModal = () => {
    setTicketModalOpened(!ticketModalOpened)
  };
  const setAppLoading = (ldg: boolean) => {
    setAppLoadg(ldg)
  };

  const setAlertMessage = (msg: AlertMessage) => {
    setAlertMsg(msg);
  };

  useEffect(() => {
    if (location.pathname.match("/reporting")) {
      setDrawerOpened(true);
      setDrawerOnTop(false);
    } else {
      setDrawerOnTop(true);
    }
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        appLoading,
        setAppLoading,
        drawerOpened,
        drawerWidth,
        drawerOnTop,
        toggleDrawer,
        setAlertMessage,
        alertMessage,
        periodics,
        setPeriodics,
        ticketModalOpened,
        toggleTicketModal
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => useContext(AppContext);
