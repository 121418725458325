import { Box, Checkbox, FormControlLabel, Tooltip, TooltipProps } from "@mui/material";
import { ToolTip } from "../../../core/type";

interface LegendCheckboxProps {
  label: string | React.ReactElement;
  toolTip?: ToolTip;
  stat?: number | string;
  isLoading?: boolean;
  icon?: any;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  children?: React.ReactElement;
}
export const LegendCheckbox = (props: LegendCheckboxProps) => {
  const {
    isLoading = false,
    label,
    toolTip,
    stat,
    children,
    icon,
    checked = true,
    onChange = () => { },
  } = props;

  // TODO STATS
  // number OR loader <-- this is missing
  // icon
  // label
  return (
    <div className="flex mb-2 items-center">
      <Tooltip placement={toolTip ? toolTip.placement : "top"} arrow title={toolTip && toolTip.text}>
        <FormControlLabel
          className="flex justify-left items-center"
          control={
            <Checkbox
              sx={{
                padding: "3px 5px 0 3px",
              }}
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={
            <div className="flex justify-left items-center gap-2">
              {stat && <span className="bold">{stat}</span>}
              {icon && (
                <img
                  alt={"legend-icon-" + label}
                  className={
                    "w-7 h-7 object-contain " +
                    (icon.className ? icon.className : "")
                  }
                  src={icon.src}
                />
              )}
              <span className="text-sm leading-none">{label}</span>
            </div>
          }
        />
      </Tooltip>
      {children}
    </div>
  );
};
