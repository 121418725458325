import { createSelector } from "@reduxjs/toolkit";
import { compareString } from "../../../_helpers/sort.helpers";
import { RootState } from "../store";
import dayjs from "dayjs";
import i18n from "../../../translations/initI18n";
import { ELEC_STATUS, VIGIE_STATUS } from "../../models/module.models";
import { isOverConso } from "../../services/Electricity.service";
import { IMarinaVigie } from "@wattsonelements/front-fdk/dist/models/Vigie.models";
import { IPlug } from "@wattsonelements/front-fdk/dist/models/Electricity.models";

enum MODULE_STORE_KEY {
  VIGIE = "vigie",
  BERTH = "berth",
  TICKET = "tickets",
  ELEC = "electricity",
}

const getModuleFilter = (
  moduleStoreKey: MODULE_STORE_KEY,
  state: RootState
) => {
  if (moduleStoreKey) {
    return state[moduleStoreKey]?.filters;
  }
};

const getModulList = (moduleStoreKey: MODULE_STORE_KEY, state: RootState) => {
  if (moduleStoreKey) {
    return state[moduleStoreKey]?.filters;
  }
};

export const getFilteredNewsList = createSelector(
  (state: RootState) => state.news.list,
  (state: RootState) => state.news.filters,
  (list, filters) => {
    return list.filter((news) => {
      const str = news.content + news.subject;
      return (
        compareString(str, filters.text) &&
        (filters.ports.some((r) => news.ports!.indexOf(r) >= 0) ||
          filters.ports.length === 0)
      );
    });
  }
);

export const getFilteredBerthList = createSelector(
  (state: RootState) => state.berth.list,
  (state: RootState) => state.berth.filters,
  (list, filters) => {
    return list.filter((berth) => {
      const str =
        berth.status +
        berth.name +
        (berth.boat_expected && berth.boat_expected?.name ? berth.boat_expected?.name : "") +
        i18n.t(`status.${berth.status}`, { ns: "berth" });
      return compareString(str, filters.text) && filters.status[berth.status];
    });
  }
);

const getVigieStatus = (vigie: IMarinaVigie) => {
  if (vigie.falco_level === "premium") {
    if (vigie.locked) {
      return VIGIE_STATUS.LOCKED;
    } else {
      return VIGIE_STATUS.UNLOCKED;
    }
  } else {
    if (vigie.devices.length > 0) {
      return VIGIE_STATUS.FREEMIUM;
    }
    return VIGIE_STATUS.UNPROTECTED;
  }
};

export const getFilteredVigieList = createSelector(
  (state: RootState) => state.vigie.list,
  (state: RootState) => state.vigie.filters,
  (list, filters) => {
    return list.filter((vigie) => {
      const flatOwners = vigie.owners.reduce((acc, o) => {
        return acc + o.first_name + o.last_name + vigie.spots;
      }, "");
      const flatSpot = vigie.spots.reduce((acc, s) => {
        return acc + s.name;
      }, "");
      const str = vigie.name + flatOwners + flatSpot + vigie.falco_level;
      return (
        compareString(str, filters.text) &&
        filters.status[getVigieStatus(vigie)]
      );
    });
  }
);

export const getFilteredTicketList = createSelector(
  (state: RootState) => state.tickets.list,
  (state: RootState) => state.tickets.closed,
  (state: RootState) => state.tickets.filters,
  (list, closed, filters) => {
    // // filter before
    // let tab = list.filter(ticket => {
    //   const date = dayjs(ticket.date_created).format(i18n.t('dates.date_hour', { ns: "common" }))
    //   const str = (ticket?.boat?.name || "") + (ticket?.spot?.name || "") + date + ticket.status
    //   return compareString(str, filters.text)
    //     && (filters.category !== "" ? filters.category === ticket?.category : 1)
    //     && (Object.keys(filters.status).length > 0 ? filters.status[ticket.status.toUpperCase()] : 1)
    // })
    // tab = [...tab.filter(ticket => ticket.priority === TicketPriority.URGENT),
    // ...tab.filter(ticket => ticket.priority === TicketPriority.INTERMEDIATE),
    // ...tab.filter(ticket => ticket.priority !== TicketPriority.URGENT && ticket.priority !== TicketPriority.INTERMEDIATE)]
    // return tab;
    return [...list, ...closed].filter((ticket) => {
      const date = dayjs(ticket.date_created).format(
        i18n.t("dates.date_hour", { ns: "common" })
      );
      const str =
        (ticket?.reference || "") +
        (ticket?.boat?.name || "") +
        (ticket?.spot?.name || "") +
        (ticket?.title || "") +
        date +
        ticket.status;
      if (Object.keys(filters.status).length === 0) return [];
      return (
        compareString(str, filters.text) &&
        (filters.category !== "" ? filters.category === ticket?.category : 1) &&
        (Object.keys(filters.status).length > 0
          ? filters.status[ticket.status.toUpperCase()]
          : 1)
      );
    });
    // .sort(function (a: Ticket, b: Ticket) {
    //   if (a.priority === TicketPriority.URGENT)
    //     return 1
    //   else return -1;
    // }).sort(function (a: Ticket, b: Ticket) {
    //   if (a.priority === TicketPriority.INTERMEDIATE)
    //     return 1
    //   else return -1;
    // });
  }
);

/**
 * Find a plug status.
 * A Plug can have multiple status (overconso is atop of in use or not in use)
 * @param plug
 * @returns array of plug status
 */
const getPlugStatus = (plug: IPlug) => {
  const status = [];
  if(plug.alerts.failure.active) {
    status.push(ELEC_STATUS.FAILURE);
    return status
  }
  if (plug.in_use) {
    status.push(ELEC_STATUS.IN_USE);
  } else {
    status.push(ELEC_STATUS.NOT_IN_USE);
  }
  return status;
};

export const getFilteredPlugList = createSelector(
  (state: RootState) => state.electricity.list,
  (state: RootState) => state.electricity.filters,
  (state: RootState) => state.electricity.selectedPowerUnit,
  (list, filters, powerunit) => {
    return list.filter((plug) => {
      const plugStatus = getPlugStatus(plug);
      const str =
        plug.name +
        plug.powerunit?.name +
        (plug?.spot_name ? plug?.spot_name : "");
      return (
        compareString(str, filters.text) &&
        compareString(plug.powerunit?.name, powerunit?.name || '') &&
        plugStatus.every((status) => filters.status[status])
      );
    });
  }
);
