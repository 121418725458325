import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

interface TableTopProps {
  datas?: readonly { [key: string]: any }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  buttons?: React.FC | React.ReactElement;
  color?: string;
  searchValue?: string
  filters?: React.FC | React.ReactElement;
}

export const TableTop = (props: TableTopProps) => {
  const { t } = useTranslation("common");
  const { datas, onChange, filters, buttons, label = t("filter"), searchValue = "" } = props;
  const [value, setValue] = useState(searchValue)
  console.log("searchValue", searchValue)
  useEffect(() => {
    setValue(searchValue)
    console.log("searchValue")
  }, [searchValue])
  return (
    <Box
      className={
        "flex items-center gap-2 justify-between w-full p-3 " +
        (props.color && props.color === "secondary"
          ? "bg-fcosecondary"
          : "bg-fcomain")
      }
    >
      <Box  
        className="flex gap-3 items-center">
        <TextField
         sx={{
          maxWidth: "250px",
        }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
            onChange(e)
          }}
          size="small"
          label={label}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {filters}
      </Box>
      <Stack direction="row" spacing={2}>
        {buttons}
      </Stack>
    </Box>
  );
};
