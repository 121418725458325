import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ContractOwner } from "../../../UX/ContractOwner";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { BoatThumbnail } from "../../../UX/Boat.thumbnail";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { BerthDeparture } from "./BerthDeparture.element";
import { IContractBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { ContractDates } from "../ContractDates.element";

interface BerthContractProps {
  contract: IContractBerth;
}
export const BerthContract: FC<BerthContractProps> = ({ contract }) => {
  const { t } = useTranslation("berth");
  const [slider, setSlider] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (!contract || !contract.date_finish || !contract.date_start) return;
    const startContract = dayjs(contract.date_start);
    const finishContract = dayjs(contract.date_finish);
    let contractDuration = Math.abs(startContract.diff(finishContract, "day", true));
    let daysLeft = Math.abs(dayjs().diff(finishContract, "day", true));

    setSlider(((contractDuration - daysLeft) * 100) / contractDuration);
    setDaysLeft(Math.round(daysLeft * 10) / 10);
  }, [contract]);

  return (
    <Card className="flex flex-grow w-full p-2">
      <CardContent className="w-full">
        <Typography component="h3" variant="h5">
          {/* {contract.contract_type}{" "} */}
          {contract.holder
            ? t("detail.contratType.owner")
            : t("detail.contratType.escale")}
        </Typography>
        <Typography component="p" variant="subtitle1">
          {contract.name}
        </Typography>

        <ContractDates contract={contract} />

        <BoatThumbnail boat={contract.boat} />
        <ContractOwner user={contract.owner} />

        <Box className="mt-5">
          <Typography align="center">
            {!!!contract?.boat?.departures && t("detail.noDeparture")}
          </Typography>
          {contract?.boat?.departures.map((d) => (
            <BerthDeparture key={d.id} departure={d} />
          ))}

          {/* {contract.departures.map((dep) => (
              <BerthDeparture departure={dep} />
            ))} */}
        </Box>
        {contract?.owner?.email && (
          <Typography align="center">
            <Button
              href={`mailto:${contract?.owner?.email}`}
              className="mx-auto"
              startIcon={<MailOutlineIcon />}
              variant="outlined"
            >
              {t("detail.btnContact")}
            </Button>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
