import { createSlice } from "@reduxjs/toolkit";
import {
  applyFiltersFromStorage,
  applyFiltersToStorage,
  FilterType,
} from "../../../_helpers/sort.helpers";
import { ElectricityState, ELEC_STATUS } from "../../models/module.models";
import {
  getElectricityList,
  getElectricityStats,
  getPlugDetail,
  getPowerunitById,
} from "../actions/Electricity.action";

const initialState = applyFiltersFromStorage(
  {
    list: [],
    powerunitList: [],
    sort: {
      order: undefined,
      orderBy: undefined,
    },
    filters: {
      text: "",
      status: {
        [ELEC_STATUS.IN_USE]: true,
        [ELEC_STATUS.NOT_IN_USE]: true,
        [ELEC_STATUS.FAILURE]: true,
      },
    },
    selected: null,
    selectedPowerUnit: null,
    selectedMarkerId: null,
    listStatus: "",
    legend: [],
    firstLoading: true,
    selectedStatus: "",
    config: {
      status: {
        available: true,
        last_update: new Date().toISOString(),
        error: {
          message: "",
          code: 200000,
        },
      },
      config: {
        enable: true,
        refresh_delay: 60*5, // secondes
        reporting: true,
      },
    },
  },
  FilterType.electricity
) as ElectricityState;

export const Electricity = createSlice({
  name: "Electricity",
  initialState,
  reducers: {
    setFirstLoading: (state, { payload }) => {
      state.firstLoading = payload;
    },
    changeSort: (state, { payload }) => {
      state.sort = { ...state.sort, ...payload };
      applyFiltersToStorage<ElectricityState>(state, FilterType.electricity);
    },
    updateFilterWithoutSaving: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
      applyFiltersToStorage<ElectricityState>(state, FilterType.electricity);
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
      state.selectedMarkerId= payload?.id || null
    },
    setSelectedMarkerId: (state, { payload }) => {
      state.selectedMarkerId = payload;
    },
    setSelectedPowerUnit: (state, { payload }) => {
      state.selectedPowerUnit = payload;
      state.selectedMarkerId= payload?.id || null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getElectricityList.pending, (state, { payload }) => {})
      .addCase(getElectricityList.rejected, (state, { payload }) => {
        state.firstLoading = false;
      })
      .addCase(getElectricityList.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.firstLoading = false;
      })
      .addCase(getElectricityStats.pending, (state, { payload }) => {})
      .addCase(getElectricityStats.fulfilled, (state, { payload }) => {
        state.stats = payload;
      })
      .addCase(getElectricityStats.rejected, (state, { payload }) => {})
      .addCase(getPowerunitById.fulfilled, (state, { payload }) => {
        state.selectedPowerUnit = payload
      })
      .addCase(getPowerunitById.pending, (state, { payload }) => {})
      .addCase(getPowerunitById.rejected, (state, { payload }) => {})
      .addCase(getPlugDetail.fulfilled, (state, { payload }) => {
        state.selected = payload as any
        console.log("getPlugDetail.fulfilled", payload);
        
      })
      // .addCase(getPlugDetail.rejected, (state, { payload }) => {
      //   log
      // })

  },
});

export default Electricity.reducer;
export const {
  updateFilter,
  setSelected,
  setSelectedMarkerId,
  setSelectedPowerUnit,
  setFirstLoading,
  updateFilterWithoutSaving,
  changeSort,
} = Electricity.actions;
