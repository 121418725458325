import { createSlice } from "@reduxjs/toolkit";
import { IPort } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { getUserPort } from "../actions/Port.actions";

export interface PortState {
  current: IPort | null;
  // areaSelected: string;
}
const initialState = {
  current: null,
  // areaSelected: "",
} as PortState;

export const Port = createSlice({
  name: "Port",
  initialState,
  reducers: {
    // changeArea: (state, { payload }) => {
    //   state.areaSelected = payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPort.pending, (state, { payload }) => {})
      .addCase(getUserPort.fulfilled, (state, { payload }) => {
        state.current = payload;
      })
      .addCase(getUserPort.rejected, (state, { payload }) => {});
  },
});

export default Port.reducer;
// export const { changeArea } = Port.actions;
