import { UseFormReturn } from "react-hook-form";

export const objectToFormData = (obj: any) => {
  let form_data = new FormData();
  for (let key in obj) {
    if (obj[key] === null) continue;
    // TODO verify picture cancelation
    if (
      key === "picture" &&
      !(obj[key] instanceof File) &&
      !(obj[key] instanceof Blob)
    ) {
      continue;
    }
    if (key === "ports" && typeof obj[key] === "string" ) continue;
    if (key === "tags") continue;
    if (Array.isArray(obj[key])) {
      form_data.append(key, JSON.stringify(obj[key]));
      // for (let i = 0; i < obj[key].length; i++) {
      //   form_data.append(eval("'" + key + "[" + i + "]'"), obj[key][i]);
      // }
    } else form_data.append(key, obj[key]);
  }
  return form_data;
};
export const resetAllFields = (form: UseFormReturn<any>) => {
  for (let key in form.getValues()) {
    form.setValue(key, "");
  }
  form.clearErrors();
};
export const setAllFields = (
  obj: any,
  form: UseFormReturn,
  replacements?: any
) => {
  for (let key in obj) {
    if (replacements && replacements.key)
      form.setValue(replacements.key, obj[key]);
    else form.setValue(key, obj[key]);
  }
  form.clearErrors();
};
