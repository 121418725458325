import { Box } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { MapBoxLayer } from "../../elements/MapBoxLayer.element";
import { MapType } from "../../../../_helpers/map.helper";
import { DashboardLayout } from "../../layouts/Dashboard.layout";
import { useTicket } from "../../../../core/services/Ticket.service";
import { getFilteredTicketList } from "../../../../core/store/selectors/Module.selector";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { useEffect, useRef, useState } from "react";
import {
  setSelected,
  setSelectedID,
} from "../../../../core/store/reducers/Ticket.reducer";
import { Geojson } from "../../../../core/models/map.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { formatAreaId, generateAreaUrl } from "../../../../_helpers/misc.helpers";
import { usePortService } from "../../../../core/services/Port.service";

export const TicketsModulePage = () => {
  const dispatch = useAppDispatch();
  const { generateTicketGeoJson } = useTicket();
  const navigate = useNavigate();
  const { id, areaId } = useParams();
  const port = useAppSelector((state) => state.ports.current);
  const listLoading = useAppSelector((state) => state.tickets.firstLoading);
  const { generateAreasStatsGeoJson } = usePortService();
  const [geojson, setGeoJson] = useState<Geojson>(generateTicketGeoJson([]));
  const selectedTicketId = useAppSelector((state) => state.tickets.selectedID);
  const loading = useAppSelector((state) => state.tickets.loading);
  const datas = useAppSelector(getFilteredTicketList);
  const mapRef = useRef<any>();

  useEffect(() => {
    if (!port) return;
    if (datas) {
      setGeoJson(generateTicketGeoJson(datas))
      // (formatAreaId(areaId) === "" && port.areas.length > 1) ?
      //   setGeoJson(generateAreasStatsGeoJson(port.areas, MapType.TICKET))
      //   : setGeoJson(generateTicketGeoJson(datas))
    }

  }, [datas, port]);

  useEffect(() => {
    if (loading || listLoading || !mapRef.current) return
    if (!id)
      setTimeout(() => mapRef.current.fitTheMap(), 500);
    else
      setTimeout(() => mapRef.current.flyToSelectedMarker(id), 500);
  }, [id, loading, listLoading, mapRef.current]);

  const clickOnMarker = (ticketId: UUID) => {
    // const selectedBerth = datas.find((d) => d.id === berthId);
    // if (selectedBerth) dispatch(setSelectedBerth(selectedBerth));
    // no port areas for the moment
    // navigate(`/admin/area/${areaId || "all"}/modules/tickets/${ticketId}`);
    navigate(`/admin/area/all/modules/tickets/${ticketId}`);
    dispatch(setSelectedID(ticketId));
  };
  // const clickOnArea = (id: UUID) => {
  //   navigate(generateAreaUrl(id))
  // };

  useEffect(() => {
    return () => {
      dispatch(setSelected(null));
    };
  }, []);

  return (
    <DashboardLayout>
      <Box className="flex-col flex flex-1 flex-grow">
        <Outlet />
      </Box>
      <MapBoxLayer
        ref={mapRef}
        displayAreasButtons={false}
        selectedMarkerId={selectedTicketId ? selectedTicketId : null}
        mapType={MapType.TICKET}
        loading={(loading && (!datas || !selectedTicketId) || listLoading)}
        geojson={geojson}
        clickOnMarker={clickOnMarker}
      />
    </DashboardLayout>
  );
};
