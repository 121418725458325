import { useAppDispatch } from "../store/hooks";
import smoke from "../../assets/img/icons/tickets/categories/smoke.svg";
import batteryLevel from "../../assets/img/icons/tickets/categories/battery-level.svg";
import mooringToConfirm from "../../assets/img/icons/tickets/categories/mooring-to-confirm.svg";
import infrastructure from "../../assets/img/icons/tickets/categories/infrastructure.svg";
import environment from "../../assets/img/icons/tickets/categories/environment.svg";
import electricity from "../../assets/img/icons/tickets/categories/electricity.svg";
import berth from "../../assets/img/icons/tickets/categories/berth.svg";
import g_electricity from "../../assets/img/icons/tickets/categories/g_electricity.svg";
import bump from "../../assets/img/icons/tickets/categories/bump-ok.svg";
import connected from "../../assets/img/icons/tickets/categories/connected.svg";
import equipment from "../../assets/img/icons/tickets/categories/equipment.svg";
import geofence from "../../assets/img/icons/tickets/categories/geofence-ok.svg";
import humidity from "../../assets/img/icons/tickets/categories/humidity.svg";
import intrusion from "../../assets/img/icons/tickets/categories/intrusion-ok.svg";
import misc from "../../assets/img/icons/tickets/categories/misc.svg";
import moored from "../../assets/img/icons/tickets/categories/moored.svg";
import mooring from "../../assets/img/icons/tickets/categories/mooring-ok.svg";
import ongoingConsumption from "../../assets/img/icons/tickets/categories/ongoing-consumption.svg";
import other from "../../assets/img/icons/tickets/categories/other.svg";
import pollution from "../../assets/img/icons/tickets/categories/pollution.svg";
import rolling from "../../assets/img/icons/tickets/categories/rolling-ok.svg";
import services from "../../assets/img/icons/tickets/categories/services.svg";
import temperature from "../../assets/img/icons/tickets/categories/temperature.svg";
import { updateTicket } from "../store/actions/Ticket.actions";
import { IAssignee, ITicket, ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import FDK from "@wattsonelements/front-fdk";

export const useTicket = () => {
  // const categories = useAppSelector((state) => state.tickets.categories);
  // const [iconCategories, setIconCategories] = useState();
  const dispatch = useAppDispatch();

  const iconCategories = {
    "battery-level": batteryLevel,
    "mooring-to-confirm": mooringToConfirm,
    infrastructure,
    environment,
    electricity,
    g_electricity,
    smoke,
    "bump-ok": bump,
    connected,
    equipment,
    "geofence-ok": geofence,
    humidity,
    "intrusion-ok": intrusion,
    "mooring-ok": mooring,
    misc,
    moored,
    berth,
    "ongoing-consumption": ongoingConsumption,
    other,
    pollution,
    "rolling-ok": rolling,
    services,
    temperature,
  };

  const getCategoryIcon = (slug: string) => {
    if (slug in iconCategories) {
      return iconCategories[slug as keyof typeof iconCategories];
    } else {
      return iconCategories.other;
    }
  };

  const getAssignees = (ticket: ITicket) => {
    const assignees: Partial<IAssignee>[] =
      ticket.ticket_intervention.length > 0
        ? ticket.ticket_intervention[0].intervention_assignee
        : [];
    return assignees;
  }

  const updateAssignee =
    (ticket: ITicket) => (assigned: Partial<IAssignee>[]) => {
      if (ticket && ticket.ticket_intervention.length > 0) {
        const tmpIntervention = {
          ...ticket.ticket_intervention[0],
          intervention_assignee: assigned,
        };
        dispatch(
          updateTicket({
            id: ticket.id,
            ticket: {
              ...ticket,
              ticket_intervention: [tmpIntervention],
            },
          })
        );
      }
    };
  const updateDescription =
    (ticket: ITicket) => (description: string) => {
      dispatch(
        updateTicket({
          id: ticket.id,
          ticket: {
            ...ticket,
            description,
          },
        })
      );
    };
  const updateTitle = (ticket: ITicket) => (title: string) => {
    dispatch(
      updateTicket({
        id: ticket.id,
        ticket: {
          ...ticket,
          title,
        },
      })
    );
  };
  const onChangeBerth = (ticket: ITicket) => (berth: IMarinaBerth) => {
    if (ticket) {
      dispatch(
        updateTicket({ id: ticket.id, ticket: { ...ticket, spot: berth } })
      );
    }
  };
  const onChangeBoat = (ticket: ITicket) => (boat: ITicketBoat) => {
    if (ticket) {
      dispatch(updateTicket({ id: ticket.id, ticket: { ...ticket, boat } }));
    }
  };

  const generateTicketGeoJson = (datas: ITicket[]) => {
    let tmpGeoJson: any = {};
    tmpGeoJson = { type: "FeatureCollection", features: [] };
    datas.map((ticket, i) => {
      if (
        ticket.localization &&
        (ticket.status === FDK.Constants.TicketConstants.TicketStatus.ONGOING ||
          ticket.status === FDK.Constants.TicketConstants.TicketStatus.OPEN)
      ) {
        const iconName =
          ticket.priority === FDK.Constants.TicketConstants.TicketPriority.URGENT ? "emergency" : "incident";
        tmpGeoJson.features.push({
          type: "Feature",
          id: ticket.id,
          properties: {
            width: 20,
            icon: `${iconName}`,
            selectedIcon: `selected_ticket`,
            anchor: { x: 10, y: 10 },
            clickable: true,
            id: ticket.id,
          },
          geometry: {
            type: "Point",
            coordinates: ticket.localization?.coordinates,
          },
        });
      }
    });
    // console.log("geojson", tmpGeoJson);

    return tmpGeoJson;
  };

  return {
    getCategoryIcon,
    getAssignees,
    updateAssignee,
    onChangeBoat,
    updateDescription,
    onChangeBerth,
    generateTicketGeoJson,
    updateTitle
  };
};
