import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FDK from "@wattsonelements/front-fdk";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../core/store/hooks";
import assistancePNG from "./../../../../assets/img/assistance-page/bg-assistance.png";

export const AssistancePage = () => {
  const user = useAppSelector((state) => state.users.profile);
  const [fdLibInserted, setfdLibInserted] = useState(false);
  const [checker, setChecker] = useState<any>(null);
  const fdId = i18next.language === "fr" ? "77000002241" : "77000004122";
  const { t } = useTranslation("app");

  useEffect(() => {
    if (user && fdLibInserted) {
      (window as any).FreshworksWidget("setLabels", {
        en: {
          launcher: "Help",
        },
      });
      const authenticateCallback = () => {
        FDK.Marina.LegacyModule.getAssistanceToken().then((res) => {
          (window as any).FreshworksWidget("authenticate", {
            token: res.data.token,
          });
        });
      };
      FDK.Marina.LegacyModule.getAssistanceToken().then((res) => {
        (window as any).FreshworksWidget("authenticate", {
          token: res.data.token,
          callback: authenticateCallback,
        });
        setTimeout(() => {
          (window as any).FreshworksWidget("show", "launcher");
          (window as any).FreshworksWidget("open");
        }, 500);
      });
      (window as any).FreshworksWidget("identify", "ticketForm", {
        name: `${user.first_name} ${user.last_name} `,
        email: user.email,
      });
      (window as any).FreshworksWidget("disable", "ticketForm", ["name", "email"]);
    }
    return () => {
      if (checker !== null) {
        window.clearInterval(checker);
        setChecker(null);
      }
      if (typeof (window as any).FreshworksWidget === "function") {
        (window as any).FreshworksWidget("hide", "launcher");
        (window as any).FreshworksWidget("close");
      }
    };
  }, [fdLibInserted, user])

  useEffect(() => {
    if (typeof (window as any).FreshworksWidget !== "function") {
      const tryGetFdFunction = () => {
        setChecker(window.setInterval(() => {
          if (typeof (window as any).FreshworksWidget === "function") {
            window.clearInterval(checker);
            setChecker(null);
            setfdLibInserted(true)
          }
        }, 200));
      };
      (window as any).fwSettings = {
        widget_id: fdId,
        locale: i18next.language
      };
      const fdScript = document.createElement("script");
      fdScript.type = "text/javascript";
      fdScript.src = `https://euc-widget.freshworks.com/widgets/${fdId}.js`;
      fdScript.addEventListener("load", tryGetFdFunction);
      document.body.appendChild(fdScript);
    } else if (fdLibInserted === false) {
      setfdLibInserted(true)
    }
  }, [fdLibInserted])

  return (
    <Box className="w-full flex flex-grow relative">
      <Box
        component="img"
        className="top-0 left-0 z-0 absolute w-full h-full object-cover object-center"
        src={assistancePNG}
      />
      <Box
        sx={{ maxWidth: "50%", zIndex: 10 }}
        className="flex flex-col pt-28 px-10"
      >
        <Typography
          sx={{ fontWeight: "bold", marginBottom: "0.5em" }}
          component="h1"
          variant="h2"
        >
          {t("helpCenter.title")}
        </Typography>
        <Typography sx={{ fontSize: "1.2em" }} component="p">
          {t("helpCenter.text")}
        </Typography>
      </Box>
      {!fdLibInserted && <Box className="absolute bottom-0 right-0 mr-20 mb-20">
        <CircularProgress />
      </Box>
      }
    </Box>
  );
};
