import { createTheme } from "@mui/material/styles";
// A custom theme for this app

const t = createTheme();

const theme = createTheme({
  ...t,
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Lato"'].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 600
        },
        h2: {
          fontWeight: 600
        },
        h3: {
          fontWeight: 600
        },
        h4: {
          fontWeight: 600
        },
        h5: {
          fontWeight: 600
        },
        h6: {
          fontWeight: 600
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          background: 'white',
          padding: '0 8px',
          borderRadius: '2px 2px 0 0',
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: "rgba(0, 0, 0, .3)"
          }
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#ffffff",
          color: "#142037",
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          flexWrap: 'wrap'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "1rem",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          color: "#142037",
          "&:hover": {
            color: "#0c8576",
          },
          [t.breakpoints.down("lg")]: { //use partial theme to access breakpoints
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#293f68",
          },
          "&$selected": {
            backgroundColor: "#3e5e93", // DOES NOT WORK. Done in index.css
          },
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundColor: "#142037",
          backgroundColor: "#142648",
          color: "#ffffff",
          boxShadow: "0px 0px 8px rgba(0,0,0,0.7)"
        },
      },
    },
  },
  palette: {
    action: {
      disabledBackground: 'rgba(164,218,211,.5)',
      disabled: 'rgba(0,0,0,.5);'
    },
    primary: {
      main: "#142037",
    },
    secondary: {
      main: "#10af9b",
      contrastText: "#fff",

    },
    error: {
      main: "#bd272d",
    },
    light: {
      main: "#fff",
      contrastText: "#142037"
    }
  },
});
export default theme;
