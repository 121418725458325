import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { debounce } from "../../../_helpers/debounce.helpers";

interface AsyncAutocompleteProps {
  label: string;
  value: any;
  onSearch: (value: string) => void;
  onChange: (value: any) => void;
  options: any[];
  optionsLabel: (option: any) => string;
  optionsCompareKey?: string;
  [key: string]: any;
}

export const AsyncAutocomplete: FC<AsyncAutocompleteProps> = ({
  label,
  value,
  onSearch,
  onChange,
  options = [],
  optionsLabel,
  optionsCompareKey = "id",
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [options]);
  return (
    <Autocomplete
      {...props}
      open={open}
      inputValue={text}
      value={value}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined
      ) => {
        onChange(value);
      }}
      onInputChange={(
        event: React.SyntheticEvent,
        nextValue: string,
        reason: string
      ) => {
        if (reason === "clear") setText("");
        if (nextValue === value && reason !== "input") return;
        if (!nextValue) {
          setText("");
        } else {
          setText(nextValue);
        }
        setLoading(true);

        debounce(() => onSearch(nextValue), 300)();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      freeSolo
      isOptionEqualToValue={(option, value) =>
        option[optionsCompareKey] === value[optionsCompareKey]
      }
      getOptionLabel={optionsLabel}
      options={text ? options : []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
