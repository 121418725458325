import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AlertType, useAppContext } from "../../../../contexts/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import { useTicket } from "../../../../../core/services/Ticket.service";
import dayjs from "dayjs";
import useInterval from "../../../../../_helpers/useInterval";
import { useForm } from "react-hook-form";
import { TicketAssignees } from "./TicketAssignees";
import { TicketBerthInput } from "./TicketBerth.element";
import { TicketBoatInput } from "./TicketBoat.element";
import { ImageUploader } from "../../../UX/ImageUploader.element";
import { createTicket } from "../../../../../core/store/actions/Ticket.actions";
import {
  objectToFormData,
  resetAllFields,
} from "../../../../../_helpers/form.helper";
import { postDocument } from "../../../../../core/store/actions/Attachments.actions";
import { removeFromUploaded, resetAttachment } from "../../../../../core/store/reducers/Attachments.reducer";
import FDK from "@wattsonelements/front-fdk";
import { IIntervention, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

interface NewTicketCategory extends ITicketCategory {
  allow_creation: boolean;
}

export const NewTicketModal = () => {
  const { t } = useTranslation("ticket");
  const categories = useAppSelector((state) => state.tickets.categories).filter(
    (cat: any) => cat.allow_creation === true && cat.parent_category !== null
  );
  const currentPort = useAppSelector((state) => state.ports.current);
  const uploaded = useAppSelector((state) => state.attachments.uploaded);
  const [nbAttachments, setNbAttachments] = useState<number>(0);
  const isAttachmentsDone = useAppSelector(
    (state) => state.attachments.uploaded.length === nbAttachments
  );
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  const [priorityChecked, setPriorityChecked] = useState(false);
  const profile = useAppSelector((state) => state.users.profile);
  const users = useAppSelector((state) => state.users);
  const [currentDate, setCurrentDate] = useState(
    dayjs().format(t("dates.abbreviation_month_date_hour", { ns: "common" }))
  );
  const { getCategoryIcon } = useTicket();
  const { setAlertMessage, toggleTicketModal, ticketModalOpened } =
    useAppContext();
  let ticketIntervention: IIntervention = {
    intervention_assignee: [],
    intervention_step: [
      {
        intervention_step_attachment: [],
        intervention_step_note: [],
      },
    ],
  };
  const defaultValues = {
    ticket_intervention: [ticketIntervention],
    category: "",
    spot_id: undefined,
    boat: undefined,
    port: currentPort?.id,
    priority: FDK.Constants.TicketConstants.TicketPriority.NORMAL,
    description: "",
    title: ""
  }
  const newTicket = useForm({
    mode: "onChange",
    defaultValues
  });

  useEffect(() => {
    return () => {
      newTicket.reset(defaultValues);
    };
  }, [])

  useEffect(() => {
    if (submitting && attachments.length > 0) {
      for (let i in attachments) {
        dispatch(postDocument(attachments[i]));
      }
      setAttachments([])
    } else if (submitting && isAttachmentsDone) {
      if (uploaded.length > 0)
        attachmentsCB()
      submit();
    }
  }, [submitting, isAttachmentsDone, attachments]);

  const submit = () => {
    setSubmitting(false);
    setLoading(true)
    dispatch(createTicket(newTicket.getValues())).then(
      (e) => {
        if (e.type === "ticket/create/rejected")
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("ticketCreationError"),
          });
        else {
          setAlertMessage!({
            type: AlertType.SUCCESS,
            message: t("successfullyCreated"),
          });
          toggleTicketModal!();
        }
        newTicket.reset(defaultValues);
        setLoading(false);
      },
      (err) => {
        setAlertMessage!({
          type: AlertType.ERROR,
          message: t("ticketCreationError"),
        });
        setLoading(false);
      }
    );
  };

  const changeImgCB = (files: File[]) => {
    setAttachments(files);
    setNbAttachments(files.length)
  };

  const attachmentsCB = () => {
    let ticket_intervention = [...newTicket.getValues("ticket_intervention")]
    ticket_intervention[0].intervention_step[0].intervention_step_attachment = [...uploaded];
    newTicket.setValue("ticket_intervention", ticket_intervention);
    setNbAttachments(0);
    dispatch(resetAttachment());
  };

  const assigneeCB = (ass: any[]) => {
    let ticket_intervention = [...newTicket.getValues("ticket_intervention")];
    ticket_intervention[0].intervention_assignee = ass
    newTicket.setValue("ticket_intervention", ticket_intervention);
  };
  const boatChanged = (boat: any) => {
    newTicket.setValue("boat", boat ? boat.id : null);
  };
  const berthChanged = (berth: any) => {
    newTicket.setValue("spot_id", berth ? berth.id : null);
  };

  const priorityChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriorityChecked(e.target.checked);
    newTicket.setValue(
      "priority",
      e.target.checked ? FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE : FDK.Constants.TicketConstants.TicketPriority.NORMAL
    );
  };

  const selectCategory = (id: string) => {
    newTicket.setValue("category", id);
  };

  const closeModal = () => {
    newTicket.reset(defaultValues);
    toggleTicketModal!();
  };

  useEffect(() => {
    const subscription = newTicket.watch((value, { name, type }) => {
      if (
        newTicket.getValues("title") !== "" &&
        newTicket.getValues("category") !== "" &&
        newTicket.getValues("port") !== "" &&
        newTicket.getValues("ticket_intervention")[0].intervention_assignee
          .length > 0
      ) {
        setIsValid(true);
      } else setIsValid(false);
    });
    return () => subscription.unsubscribe();
  }, [newTicket.watch]);

  useEffect(() => {
    newTicket.setValue("port", currentPort?.id);
  }, [currentPort]);

  useInterval(() => {
    setCurrentDate(
      dayjs().format(t("dates.abbreviation_month_date_hour", { ns: "common" }))
    );
  }, 30000);

  return (
    <Modal
      className="overflow-y-auto"
      open={ticketModalOpened}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        onSubmit={newTicket.handleSubmit(() => setSubmitting(true))}
        className="block overflow-hidden relative rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-5/6 w-3/4 shadow-md"
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <Box className="absolute top-0 w-full left-0 bg-fcomain p-6 text-white flex justify-between z-10">
          <Typography component="h1" variant="h4" color="white">
            {t("form.h1")}
          </Typography>
          <Button onClick={closeModal}>
            <CloseIcon className="text-white" fontSize="large" />
          </Button>
        </Box>
        <Box className="overflow-y-auto p-8 pt-28 w-full h-full relative">
          <Box className="w-full flex gap-4">
            <Box className="flex w-4/5 flex-col">
              <Tooltip placement="top-start" arrow title={t("form.categoryToolTip")}>
                <Typography className="font-bold" component="h1" variant="h6">
                  {t("form.category")}
                </Typography>
              </Tooltip>
              <Box className="flex justify-start w-full gap-2 mt-5 flex-wrap">
                {categories.map((cat: ITicketCategory, i) => (
                  <Button
                    onClick={() => selectCategory(cat.id)}
                    component="span"
                    className="block"
                    sx={{ minWidth: "auto", padding: 0, margin: 0 }}
                    key={`cat-${i}`}
                  >
                    <Box
                      className={
                        "transition ease rounded-xl block py-2 px-5 w-full h-full gap-y-2 flex flex-col items-center justify-center " +
                        (newTicket.watch("category") === cat.id
                          ? "bg-fcomain"
                          : "bg-gray-400 hover:bg-gray-300")
                      }
                    >
                      <Box
                        component="img"
                        className="h-8"
                        src={getCategoryIcon(cat.slug)}
                      />
                      <Typography className="text-white">
                        {t(cat.label)}
                      </Typography>
                    </Box>
                  </Button>
                ))}
              </Box>
              <Divider sx={{ marginTop: "30px", marginBottom: "20px" }} />
              <Box className="flex justify-between">
                <Typography component="h1" variant="h6">
                  {categories && categories.length > 0 && newTicket.getValues("category") !== "" ? categories!.find(cat => cat.id === newTicket.getValues("category"))!.label : " "}
                </Typography>
                <Typography component="h1" variant="h6">
                  {t("form.attributions")}
                </Typography>
              </Box>
              <Box className="flex justify-between">
                <Box className="flex items-center mt-5">
                  <Avatar src={profile?.picture} />
                  <Box className="ml-3">
                    <Box className="text-sm">
                      {t("form.createdBy")}{" "}
                      <span className="font-bold">
                        {profile?.first_name} {profile?.last_name}
                      </span>
                    </Box>
                    <Box className="text-sm text-gray-400">{currentDate}</Box>
                  </Box>
                </Box>
                <Box className="flex justify-center items-center gap-2">
                  <p className="text-sm m-0 p-0 block font-bold">
                    {t("form.ref")}
                  </p>
                  <Box className="flex w-full justify-end">
                    <TicketAssignees
                      displayAllAssignees={true}
                      onChange={assigneeCB}
                      canEdit={true}
                      ticket={null}
                    />
                  </Box>
                </Box>
              </Box>
              <TextField
                sx={{ marginTop: "20px" }}
                label={t("form.title")}
                required
                inputProps={
                  { maxLength: 40 }
                }
                {...newTicket.register("title", { required: true, maxLength: 40 })}
              />
              <TextField
                sx={{ marginTop: "20px" }}
                label={t("form.description")}
                multiline
                minRows={4}
                {...newTicket.register("description", { required: false })}
              />
              <Typography
                sx={{ marginTop: "30px", marginBottom: "20px" }}
                component="h1"
                variant="h6"
              >
                {t("form.attachment")}
              </Typography>
              <ImageUploader
                multiple={true}
                thumb={true}
                simpleButton={false}
                onChange={changeImgCB}
                currentImg={undefined}
                deleteButton={false}
                addBtn={t("form.add")}
                name="attachments"
                form={null}
                preview={true}
              />
            </Box>
            <Box className="flex w-1/5 flex-col gap-y-8">
              <Tooltip placement="left" arrow title={t("form.intermediateToolTip")}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={priorityChecked}
                      onChange={priorityChanged}
                      color="error"
                    />
                  }
                  label={t("form.intermediate")}
                />
              </Tooltip>
              <TicketBerthInput onChange={berthChanged} disabled={false} />
              <TicketBoatInput onChange={boatChanged} disabled={false} />
            </Box>
          </Box>
          <Box className="flex flex-grow justify-center pt-6">
            <Button
              disabled={!isValid}
              type="submit"
              size="large"
              variant="contained"
              color="secondary"
            >
              {(loading || submitting) ? (
                <CircularProgress size={20} />
              ) : (
                <>{t("form.confirmCreation")}</>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
