import React, { useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "../../../assets/img/menu/HomeIcon";
import ReportingIcon from "../../../assets/img/menu/ReportingIcon";
import { List, Tooltip } from "@mui/material";
import BerthIcon from "../../../assets/img/menu/BerthIcon";
import TicketIcon from "../../../assets/img/menu/TicketIcon";
import ElectricityIcon from "../../../assets/img/menu/ElectricityIcon";
import UserIcon from "../../../assets/img/menu/UserIcon";
import NewsIcon from "../../../assets/img/menu/NewsIcon";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";

export const HarborMenu: React.FC = () => {
  const { t } = useTranslation("app");
  const { areaId } = useParams();
  const { drawerOpened, toggleDrawer } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    if (drawerOpened) toggleDrawer!();
  }, [location]);

  return (
    <>
      <Tooltip placement="right" arrow title={t("menu.bloc1.overviewToolTip")}>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to={`/admin/area/${areaId || "all"}/modules/berths`}
        >
          <ListItemButton>
            <HomeIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc1.overview")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      <Tooltip placement="right" arrow title={t("menu.bloc1.subtitleToolTip")}>
        <NavLink to="/admin/reporting/berths">
          <ListItemButton>
            <ReportingIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc1.subtitle")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      <List component="div" disablePadding>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/reporting/berths"
        >
          <ListItemButton sx={{ pl: 7 }}>
            <BerthIcon whiteSquare={true} />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-medium text-md">
                  {t("menu.modules.berth")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/reporting/electricity"
        >
          <ListItemButton sx={{ pl: 7 }}>
            <ElectricityIcon whiteSquare={true} />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-medium text-md">
                  {t("menu.modules.electricity")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/reporting/tickets"
        >
          <ListItemButton sx={{ pl: 7 }}>
            <TicketIcon whiteSquare={true} />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-medium text-md">
                  {t("menu.modules.ticket")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </List>
    </>
  );
};

export const ManagementMenu = () => {
  const { t } = useTranslation("app");
  return (
    <>
      <Tooltip placement="right" arrow title={t("menu.bloc2.newsToolTip")}>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/cms/news"
        >
          <ListItemButton>
            <NewsIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">{t("menu.bloc2.news")}</div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
      <Tooltip placement="right" arrow title={t("menu.bloc2.usersToolTip")}>
        <NavLink
          className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
          to="/admin/cms/users"
        >
          <ListItemButton>
            <UserIcon />
            <ListItemText
              disableTypography
              primary={
                <div className="ml-4 font-bold text-md">
                  {t("menu.bloc2.users")}
                </div>
              }
            />
          </ListItemButton>
        </NavLink>
      </Tooltip>
    </>
  );
};
