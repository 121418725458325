import FDK from "@wattsonelements/front-fdk";
import { ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncAutocomplete } from "../../../UX/AsyncAutocomplete";

interface TicketBoatInputProps {
  onChange: (berth: ITicketBoat) => void;
  initialValue?: any;
  [key: string]: any;
}

export const TicketBoatInput: FC<TicketBoatInputProps> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const { t } = useTranslation("ticket");

  const [options, setOptions] = useState<ITicketBoat[]>([]);

  const onSearch = (name: string) => {
    if(!name) {
      setOptions([])
      return
    }
    FDK.Marina.BoatModule.searchBoat({name}).then((res) => {
      setOptions(res.data);
    });
  };

  return (
    <AsyncAutocomplete
      {...props}
      label={t("form.boat")}
      value={initialValue}
      onSearch={onSearch}
      onChange={onChange}
      options={options}
      optionsLabel={(option: ITicketBoat) => option.name}
    />
  );
};
