import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTicketDetail,
  updateTicket,
} from "../../../../../core/store/actions/Ticket.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import dayjs from "dayjs";
import { TicketAssignees } from "./TicketAssignees";
import { TicketBerthInput } from "./TicketBerth.element";
import { TicketBoatInput } from "./TicketBoat.element";

import { useTicket } from "../../../../../core/services/Ticket.service";
import { Ticketchat } from "./TicketChat";
import {
  setSelected,
  setSelectedID,
} from "../../../../../core/store/reducers/Ticket.reducer";
import useConfirm from "../../../../../_helpers/useConfirm";
import { TicketDetailLoading } from "./TicketDetailLoading";
import { usePeriodic } from "../../../../../core/services/Periodic.service";
import { usePrevious } from "../../../../../_helpers/usePrevious.helpers";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export const TicketDetail = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("ticket");
  const navigate = useNavigate();
  const { id } = useParams<{ id: UUID }>();
  const { updateAssignee, getAssignees, onChangeBerth, onChangeBoat, updateDescription, updateTitle } = useTicket();
  const { confirm } = useConfirm();
  const { startPeriodic, stopPeriodic } = usePeriodic();
  const [loading, setLoading] = useState(true);
  const [plugName, setPlugName] = useState("");
  const ticket = useAppSelector((state) => state.tickets.selected);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const disabled =
    ticket?.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
    ticket?.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED;

  useEffect(() => {
    if (id) {
      dispatch(setSelectedID(id));
      // dispatch(getTicketDetail({ id }))

      startPeriodic("ticket-detail", 5, () =>
        dispatch(getTicketDetail({ id }))
      );
    }

    return () => {
      stopPeriodic("ticket-detail");
      dispatch(setSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    console.log("TICKET EFFECT", id, ticket?.id, id === ticket?.id);

    if (id && ticket && id === ticket?.id) {
      setLoading(false);
      setDescription(ticket.description);
      setTitle(ticket?.title || "")
      // this state if we had to get the power unit name + plug name
      if (ticket?.plug !== null) {
        // get power unit name
        //...
        setPlugName(ticket!.plug!.name)
      }
    }
  }, [id, ticket]);

  const cancelDescription = () => {
    setEditDescription(false);
    setDescription(ticket!.description);
  }

  const checkUpdateTitle = () => {
    if (title !== ticket!.title)
      updateTitle(ticket!)(title);
  }
  const checkUpdateDescription = () => {
    if (description !== ticket!.description)
      updateDescription(ticket!)(description)
  }

  const handleStatusClose = async () => {
    if (!(await confirm(t("confirmCloseTicket.text")))) return;
    dispatch(
      updateTicket({
        id: ticket!.id,
        ticket: { ...ticket, status: FDK.Constants.TicketConstants.TicketStatus.CLOSED },
      })
    );
  };
  const handleStatusReOpen = async () => {
    if (!(await confirm(t("confirmReopenTicket.text")))) return;
    dispatch(
      updateTicket({
        id: ticket!.id,
        ticket: { ...ticket, status: FDK.Constants.TicketConstants.TicketStatus.OPEN },
      })
    );
  };
  return (
    <Paper className="p-4">
      <Box className="flex items-center justify-between mb-2">
        <Box className="flex items-center gap-5">
          <Button
            onClick={() => {
              navigate(-1);
              // dispatch(setSelected(null));
            }}
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
          >
            {t("buttons.back", { ns: "common" })}
          </Button>
          <Box>


            {ticket?.title &&
              <Box component="h1" className="font-extrabold">

                {ticket?.title}

              </Box>
            }
            {ticket?.reference &&
              <Chip className="mr-2 font-bold" label={ticket?.reference} />
            }

            {t("form.createdAt")}{" "}
            {dayjs(ticket?.date_created).format(t("dates.full", { ns: "common" }))}
          </Box>
        </Box>
        {ticket?.priority !== FDK.Constants.TicketConstants.TicketPriority.URGENT && (
          <Tooltip placement="left" arrow title={t("form.urgentToolTip")}>
            <FormControlLabel
              control={<Switch color="error" />}
              label={t("form.intermediate")}
              checked={ticket?.priority === FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                checked: boolean
              ) => {
                dispatch(
                  updateTicket({
                    ticket: {
                      ...ticket,
                      priority: checked
                        ? FDK.Constants.TicketConstants.TicketPriority.INTERMEDIATE
                        : FDK.Constants.TicketConstants.TicketPriority.NORMAL,
                    },
                    id: ticket!.id,
                  })
                );
              }}
            />
          </Tooltip>
        )}
        <Box className="flex flex-col gap-3">
          {ticket?.priority === FDK.Constants.TicketConstants.TicketPriority.URGENT &&
            <Chip color="error" className="uppercase" label={t("form.urgent")} />
          }
        </Box>
      </Box>
      <Divider />
      {(loading || !ticket) && <TicketDetailLoading />}

      {!loading && ticket && (
        <>
          <Box className="grid grid-cols-2 mt-2">
            <Box className="p-5">
              <Box className="flex items-center">
                <Avatar src={ticket?.creator?.avatar} />
                <Box className="ml-3">
                  <Typography variant="subtitle2"> {t("createdBy")}</Typography>
                  <Typography variant="h5">
                    {ticket?.creator?.first_name} {ticket?.creator?.last_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {ticket?.boat?.name}
                  </Typography>
                </Box>
              </Box>
              <Stack className="mt-2" spacing={2}>
                {/* {ticket.description && */}
                <Box>
                  {/* <p className="text-sm m-0 p-0 block font-bold">
                    {t("form.description")}
                  </p> */}
                  {/* <p>{ticket.description}</p> */}
                  <TextField
                    disabled={disabled}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    onBlur={checkUpdateTitle}
                    className="w-full"
                    sx={{ marginTop: "10px" }}
                    label={t("form.title")}
                    value={title}
                    inputProps={
                      { maxLength: 40 }
                    }

                  />
                  <TextField
                    disabled={disabled}
                    onChange={(e) => setDescription(e.target.value)}
                    // onBlur={() => setEditDescription(false)}
                    // onBlur={cancelDescription}
                    onBlur={checkUpdateDescription}
                    // onFocus={() => setEditDescription(true)}
                    className="w-full"
                    sx={{ marginTop: "10px" }}
                    label={t("form.description")}
                    multiline
                    value={description}
                    minRows={4}
                  />
                  {/* {editDescription && <Box className="flex items-center justify-end gap-2 mt-2">
                    <Button onMouseDown={() => updateDescription(ticket)(description)} variant="contained" color="primary" size={"small"}>{t('form.save')}</Button>
                    <Button onMouseDown={cancelDescription} size={"small"}>{t('form.cancel')}</Button>
                  </Box>} */}
                </Box>
                {/* } */}
                <Box className="flex justify-start items-center gap-2">
                  <p className="text-sm m-0 p-0 block font-bold">
                    {t("form.ref") + (ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED && getAssignees(ticket).length < 1 ? " : " + getAssignees(ticket).length : "")}
                  </p>
                  <TicketAssignees
                    onChange={updateAssignee(ticket)}
                    canEdit={!disabled}
                    ticket={ticket}
                    displayLabel={true}
                  />
                </Box>
                {ticket?.plug !== null &&
                  <TextField aria-readonly value={plugName} label={t('form.plug')} disabled={true} />}
                <TicketBerthInput
                  initialValue={ticket?.spot}
                  onChange={onChangeBerth(ticket)}
                  disabled={disabled}
                />
                <TicketBoatInput
                  initialValue={ticket?.boat}
                  onChange={onChangeBoat(ticket)}
                  disabled={disabled}
                />
                {ticket.status !== FDK.Constants.TicketConstants.TicketStatus.CLOSED &&
                  ticket.status !== FDK.Constants.TicketConstants.TicketStatus.ARCHIVED && (
                    <Button
                      onClick={() => {
                        // TODO modal
                        handleStatusClose();
                      }}
                    >
                      {t("form.btnClose")}
                    </Button>
                  )}
              </Stack>
            </Box>
            <Box className="flex w-full flex-grow p-5 bg-fcogrey bg-opacity-40 rounded-xl">
              <Ticketchat />
            </Box>
          </Box>

          {
            (ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
              ticket.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED) && (
              <Alert
                className="mt-4"
                severity="info"
                action={
                  <Button
                    onClick={() => {
                      handleStatusReOpen();
                    }}
                    color="inherit"
                    size="small"
                  >
                    {t("form.reOpen")}
                  </Button>
                }
              >
                {t("form.closedAt")}
                {dayjs(ticket.date_closed).format(
                  t("dates.date_hour", { ns: "common" })
                )}
              </Alert>
            )
          }
        </>
      )}
    </Paper >
  );
};
