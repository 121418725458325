import { useParams } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

enum MODULE_LIST_V1TOV2 {
  // emplacement = "emplacement",
  berths = "emplacement",
  electricity = "electricity",
}

export const useModule = () => {
  const moduleConfig = useAppSelector(
    ({ ports }) => ports.current?.config.modules
  );
  const { areaId } = useParams();
  const isModuleActivated = (moduleName: string) => {
    if (!moduleConfig) return false; // config not loaded
    if (
      !(
        MODULE_LIST_V1TOV2[moduleName as keyof typeof MODULE_LIST_V1TOV2] in
        moduleConfig
      )
    )
      return true; // default true, like vigie
    // TODO CHANGE TO V2
    const config =
      moduleConfig[
      MODULE_LIST_V1TOV2[
      moduleName as keyof typeof MODULE_LIST_V1TOV2
      ] as keyof typeof moduleConfig
      ];
    return config.overview;
  };

  // TODO refactor with v2, ugly version for now
  const findFirstActivatedModule = () => {
    if (moduleConfig?.emplacement.overview === true) {
      return `/admin/area/${areaId || "all"}/modules/berths`
    }
    if (moduleConfig?.electricity.overview === true) {
      return `/admin/area/${areaId || "all"}/modules/electricity`;
    }
    return `/admin/area/${areaId || "all"}/modules/vigie`;
  };

  return {
    isModuleActivated,
    findFirstActivatedModule,
  };
};
